import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { RequestStatusType } from '../../../../common/types';
import axiosInstance from '../../../../common/interceptors';
import { useModalContext } from '../../../../common/hooks';
import Button from '../../../button';
import Pagination from '../../../pagination';
import { ArrowUpDownIcon } from '../../../svg';
import SurveyItemDeleteButton from '../../../survey-item-delete-button';
import { paginationLimit, sortTimeOrders } from '../../../../common/data';
import { handleAxiosError } from '../../../../common/helper';
import { MonthlyResultsType } from './types';

function SurveyResultMonthlyList() {
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('request');
  const [results, setResults] = useState<MonthlyResultsType>([]);
  const [totalPages, setTotalPages] = useState(0);
  const modalContextValue = useModalContext();
  const [filterParams, setFilterParams] = useState({
    currentPage: 1,
    sortBy: 'monthsBack',
    sortOrder: 1,
  });

  const getResults = async () => {
    try {
      setRequestStatus('request');
      const { currentPage, sortBy, sortOrder } = filterParams;
      const offset = (currentPage - 1) * paginationLimit;
      const response = await axiosInstance.get(
        `/survey/submissions/monthly?offset=${offset}&limit=${paginationLimit}&sortBy=${sortBy}&sortOrder=${sortTimeOrders[sortOrder]}`
      );
      setTotalPages(Math.ceil(response.data.count / paginationLimit));
      setResults(response.data.submissions);
      setRequestStatus('resolved');
    } catch (error: any) {
      setTotalPages(0);
      setResults([]);
      setRequestStatus('rejected');
    }
  };

  const showModal = (id: string) => {
    const deleteSurveyResult = async () => {
      try {
        await axiosInstance.delete(`/survey/submissions/monthly/delete?monthYear=${id}`);
        getResults();
        modalContextValue.hideModal();
      } catch (error: any) {
        const errorDetails = handleAxiosError(error);
        modalContextValue.setOutcomeModal('error', errorDetails.message);
      }
    };

    const getModalBody = () => {
      return (
        <div>
          <div>
            <div className="text-center font-bold">
              You are about to delete a month of survey results, if you are sure you want to delete
              this information, click the [Delete] button, otherwise, click [Cancel]
            </div>
          </div>
          <div className="text-center mt-5 mb-3">
            <Formik initialValues={{ delete: true }} onSubmit={deleteSurveyResult}>
              {({ isSubmitting }) => (
                <Form autoComplete="off" role="form">
                  <Button
                    type="submit"
                    label="Delete"
                    customClass="bg-red text-white mx-1 min-w-[120px]"
                    disabled={isSubmitting}
                  />
                  <Button
                    type="button"
                    label="Cancel"
                    handleClick={modalContextValue.hideModal}
                    customClass="bg-black-light text-white mx-1 min-w-[120px]"
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    };

    modalContextValue.setDataForModal({
      title: 'Delete Monthly Survey Results',
      headerClass: ' bg-red text-white',
      modalBody: getModalBody,
    });
  };

  const handlePageChange = (pageNumber: number) => {
    setFilterParams({ ...filterParams, currentPage: pageNumber });
  };
  const handleSortClick = () => {
    setFilterParams({ ...filterParams, sortOrder: 1 - filterParams.sortOrder, currentPage: 1 });
  };

  useEffect(() => {
    getResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  return (
    <div>
      <div className="border border-t-0 border-gray-mid mb-10">
        <table className="w-full">
          <thead className="text-lg font-bold bg-brown-dark text-white text-left">
            <tr className="p-3">
              <th>
                <div className="flex items-center">
                  <span className="mr-2">Month</span>
                  <button type="button" onClick={handleSortClick}>
                    <ArrowUpDownIcon width={16} height={16} />
                  </button>
                </div>
              </th>
              <th className="text-right">1</th>
              <th className="text-right">2</th>
              <th className="text-right">3</th>
              <th className="text-right">4</th>
              <th className="text-right">5</th>
              <th className="text-right">Count</th>
              <th className="w-[120px]"></th>
            </tr>
          </thead>
          <tbody className="font-bold">
            {requestStatus === 'request' ? (
              <tr>
                <td colSpan={8} className="text-center">
                  Loading . . .
                </td>
              </tr>
            ) : results && results.length > 0 ? (
              results.map((item, index) => (
                <tr
                  key={item.monthYear}
                  className={`${index % 2 === 0 ? ' bg-gray-light ' : 'bg-white'}`}
                >
                  <td>{item.monthYear}</td>
                  <td className="text-right">{item['1']}%</td>
                  <td className="text-right">{item['2']}%</td>
                  <td className="text-right">{item['3']}%</td>
                  <td className="text-right">{item['4']}%</td>
                  <td className="text-right">{item['5']}%</td>
                  <td className="text-right">{item.count}</td>
                  <td className="text-right">
                    <SurveyItemDeleteButton
                      id={item.monthYear}
                      allowDelete={item.monthsBack > 6}
                      callback={showModal}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  No results available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalPages > 1 && (
        <Pagination
          currentPage={filterParams.currentPage}
          totalPages={totalPages}
          callback={handlePageChange}
        />
      )}
    </div>
  );
}

export default SurveyResultMonthlyList;
