import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useModalContext } from '../../../../../../common/hooks';
import axiosInstance from '../../../../../../common/interceptors';
import { handleAxiosError } from '../../../../../../common/helper';
import { ExclamationTriangleFillIcon } from '../../../../../svg';
import Button from '../../../../../button';
import { TourStartOverButtonType } from './types';

function TourStartOverButton({ tourId }: TourStartOverButtonType) {
  const navigate = useNavigate();
  const modalContextValue = useModalContext();

  const navigateToAddTour = () => {
    navigate(`/tours/add`, { replace: true });
  };

  const deleteTour = async () => {
    try {
      await axiosInstance.delete(`/tour/delete?id=${tourId}`);
      modalContextValue.hideModal();
      navigateToAddTour();
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    }
  };

  const getConfirmModalBody = () => {
    return (
      <div>
        <div>
          <div className="text-center font-bold">
            <div className="mb-3">
              <ExclamationTriangleFillIcon width={60} height={60} customClass="mx-auto" />
            </div>
            <div>
              Are you sure you want to permanently delete this tour package? All the work you have
              created will automatically be deleted forever. This cannot be undone.
            </div>
          </div>
        </div>
        <div className="text-center mt-5 mb-3">
          <Formik initialValues={{ delete: true }} onSubmit={deleteTour}>
            {({ isSubmitting }) => (
              <Form autoComplete="off" role="form">
                <Button
                  type="submit"
                  label="Yes, Delete Forever"
                  customClass="bg-red text-white mx-1 min-w-[180px]"
                  disabled={isSubmitting}
                />
                <Button
                  type="button"
                  label="Cancel"
                  handleClick={modalContextValue.hideModal}
                  customClass="bg-black-light text-white mx-1 min-w-[120px]"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  const showConfirmModal = () => {
    modalContextValue.setDataForModal({
      title: 'Permanently Delete Tour',
      headerClass: 'bg-red text-white',
      modalBody: getConfirmModalBody,
    });
  };

  return (
    <Button
      type="button"
      label="Start Over"
      handleClick={showConfirmModal}
      customClass=" bg-black-light text-white"
    />
  );
}

export default TourStartOverButton;
