import { object, string } from 'yup';
import { userRoles, userStatus } from '../../../../common/data';

const { admin, superAdmin } = userRoles;
const { active, inActive } = userStatus;

export const editUserSchema = object({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  role: string()
    .required('Required')
    .oneOf(
      [admin.key, superAdmin.key],
      'Role must be one of the following values: Administrator, Super Admin'
    ),
  status: string()
    .required('Required')
    .oneOf(
      [active.key, inActive.key],
      'Role must be one of the following values: Active, Inactive'
    ),
});

//
