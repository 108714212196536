export const instructionalImageDimensions = {
  width: 400,
  height: 250,
};

export const bisonMappings = [
  { key: 'Rawhide', mapping: 'Suitcases' },
  { key: 'Hair', mapping: 'Pillows' },
  { key: 'Tan Hide', mapping: 'Clothing' },
  { key: 'Horns', mapping: 'Drinking Cup' },
  { key: 'Muscles', mapping: 'Meat' },
  { key: 'Stomach', mapping: 'Cooking Pots' },
  { key: 'Bones', mapping: 'Knives' },
  { key: 'Hooves', mapping: 'Glue' },
];
