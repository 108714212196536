import { array, mixed, object, string } from 'yup';
import { validateImageDimensions } from '../../../../common/helper';
import { instructionalImageDimensions } from './data';

export const formSchema = object({
  name: string().required('Required'),
  description: string().required('Required'),
  images: array()
    .of(
      object().shape(
        {
          name: string().when('image', {
            is: (val: string | File) => val,
            then: (schema) => schema.required('Required'),
          }),
          image: mixed<string | File>()
            .when('name', {
              is: (val: string) => val,
              then: (schema) => schema.required('Required'),
            })
            .test(
              'valid-image-dimensions',
              `Image dimensions must be exactly ${instructionalImageDimensions.width}w x ${instructionalImageDimensions.height}h`,
              async (file) => {
                if (!file || typeof file === 'string') return true;
                const isValid = await validateImageDimensions(
                  file,
                  instructionalImageDimensions.width,
                  instructionalImageDimensions.height
                );
                return isValid;
              }
            ),
        },
        [['name', 'image']]
      )
    )
    .required('Required')
    .test('min-one-image', 'At least one image is required', (values) => {
      return values.some((value) => value.image);
    }),
  // instructions: field-level validation
});
