import { userRoles } from '../../common/data';

export const list = [
  {
    id: 1,
    name: 'User Management',
    link: '/user-management',
    restriction: [userRoles.superAdmin.key],
  },
  {
    id: 2,
    name: 'Create New Tour Package',
    link: '/tours/add',
  },
  {
    id: 3,
    name: 'Manage Tours',
    link: '/tours',
  },
  {
    id: 4,
    name: 'Survey Questions',
    link: '/survey-questions',
  },
  {
    id: 5,
    name: 'Survey Results',
    link: '/survey-results',
  },
];
