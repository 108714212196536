import { useEffect, useState } from 'react';
import { RequestStatusType } from '../../../common/types';
import EditUserIconBox from '../edit-user-icon-box';
import axiosInstance from '../../../common/interceptors';
import { userRolesKeyBased, userStatus, userStatusKeyBased } from '../../../common/data';
import { UserManagementTableType, UsersType } from './types';

function UserManagementTable({ usersApiCount, editCallback }: UserManagementTableType) {
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('request');
  const [userlist, setUserList] = useState<UsersType>([]);

  const getUsers = async () => {
    try {
      const response = await axiosInstance.get(`/user/all?offset=0&limit=100`);
      setUserList(response.data.user);
      setRequestStatus('resolved');
    } catch (error: any) {
      setUserList([]);
      setRequestStatus('rejected');
    }
  };

  useEffect(() => {
    if (usersApiCount > 1) {
      setRequestStatus('request');
    }
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersApiCount]);

  return (
    <div>
      <table className="w-full">
        <thead className="text-lg font-bold bg-brown-dark text-white text-left">
          <tr className="p-3">
            <th className="w-[60px]">User</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Role</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody className="font-bold">
          {requestStatus === 'request' ? (
            <tr>
              <td colSpan={5} className="text-center">
                Loading . . .
              </td>
            </tr>
          ) : userlist && userlist.length > 0 ? (
            userlist.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? ' bg-gray-light ' : 'bg-white'} ${item.status === userStatus.inActive.key ? ' text-red ' : 'text-black'}`}
              >
                <td className="flex text-black">
                  <EditUserIconBox userDetails={item} editCallback={editCallback} />
                </td>
                <td className="capitalize">{item.firstName}</td>
                <td className="capitalize">{item.lastName}</td>
                <td>{userRolesKeyBased[item.role]}</td>
                <td className="capitalize">{userStatusKeyBased[item.status]}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="text-center">
                No users available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default UserManagementTable;
