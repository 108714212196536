import { Field, Form, Formik } from 'formik';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import HorizontalRule from '../../../horizontal-rule';
import CountHeader from '../count-header';
import Button from '../../../button';
import { formSchema } from './schema';
import { templateList } from './data';

function TourSelectTemplate() {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();

  const navigateToAddActivity = (values: { templateSelected: string }) => {
    navigate(`/tours/add/create-activity`, {
      replace: true,
      state: {
        tourId: state.tourId,
        from: 'select-activity-type',
        activityType: values.templateSelected,
      },
    });
  };

  return state && state.tourId ? (
    <div className="font-bold">
      <div className="my-5">
        <CountHeader count={2} title="Select Activity Type" />
      </div>
      <HorizontalRule />
      <div className="mt-5">
        <Formik
          initialValues={{ templateSelected: '' }}
          validationSchema={formSchema}
          onSubmit={navigateToAddActivity}
        >
          {({ dirty }) => (
            <Form autoComplete="off">
              <div
                role="group"
                aria-labelledby="activity-group"
                className="flex flex-wrap max-w-[1160px] m-auto mb-5"
              >
                {templateList.map((template) => (
                  <div className="text-center cursor-pointer m-5" key={template.id}>
                    <Field
                      type="radio"
                      name="templateSelected"
                      id={template.id}
                      className="w-[30px] h-[30px]"
                      value={template.value}
                    />
                    <label htmlFor={template.id} className="cursor-pointer">
                      <img src={template.imgSrc} alt="Template" className="m-auto" />
                    </label>
                  </div>
                ))}
              </div>
              <HorizontalRule />
              <div className="mt-4 flex space-x-4">
              <Button
                  type="button"
                  label="Back"
                  handleClick={() => navigate(-1)}
                  customClass="bg-blue text-white"
                />
                <Button
                  type="submit"
                  label="Next"
                  disabled={!dirty}
                  customClass="bg-blue text-white"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  ) : (
    <Navigate to="/tours/add" replace={true} />
  );
}

export default TourSelectTemplate;
