// import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import Button from '../../../button';
import { userRoleList, userStatusList } from '../../../../common/data';
import UserManagementFormLabel from '../label';
import axiosInstance from '../../../../common/interceptors';
import { handleAxiosError } from '../../../../common/helper';
import { useModalContext } from '../../../../common/hooks';
import { addUserSchema } from './schema';
import { UserManagementAddFormType } from './types';

function UserManagementAddForm({ updateUsersAPiCount }: UserManagementAddFormType) {
  const modalContextValue = useModalContext();
  const handleSubmit = async (values: any, formikHelpers: { resetForm: () => void }) => {
    try {
      await axiosInstance.post('/user', {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        role: values.role,
        status: values.status,
      });
      formikHelpers.resetForm();
      updateUsersAPiCount();
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    }
  };
  return (
    <div className="font-bold p-4">
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          role: '',
          status: '',
        }}
        validationSchema={addUserSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <div className="flex mb-7">
              <UserManagementFormLabel label="First Name:" htmlFor="firstName" />
              <div className="grow">
                <Field type="text" name="firstName" id="firstName" />
                <ErrorMessage name="firstName" component="div" className="form-error" />
              </div>
            </div>
            <div className="flex mb-7">
              <UserManagementFormLabel label="Last Name:" htmlFor="lastName" />
              <div className="grow">
                <Field type="text" name="lastName" id="lastName" />
                <ErrorMessage name="lastName" component="div" className="form-error" />
              </div>
            </div>
            <div className="flex mb-7">
              <UserManagementFormLabel label="Email:" htmlFor="email" />
              <div className="grow">
                <Field type="email" name="email" id="email" />
                <ErrorMessage name="email" component="div" className="form-error" />
                <div className="grow  p-3 bg-gray-light mt-4">
                  Email address always represents the User's{' '}
                  <span className="whitespace-nowrap">"User Name"</span>
                </div>
              </div>
            </div>
            <div className="flex mb-7">
              <UserManagementFormLabel label="Password:" htmlFor="password" />
              <div className="grow">
                <Field type="text" name="password" id="password" />
                <ErrorMessage name="password" component="div" className="form-error" />
                <div className="grow  p-3 bg-gray-light mt-4">
                  <div>
                    Password must be a minimum of (10) characters, and must contain (2) capital
                    letters and (3) digits.
                  </div>

                  <div className="mt-3">
                    <div>Example 1: TerRi453r9X</div>
                    <div>Example 2: Ri58YY3Ox!</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mb-7">
              <UserManagementFormLabel label="Role:" htmlFor="role" />
              <div className="grow">
                <Field as="select" name="role" id="role">
                  <option value="">Select Role</option>
                  {userRoleList.map((role) => (
                    <option key={role.key} value={role.key}>
                      {role.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="role" component="div" className="form-error" />
              </div>
            </div>
            <div className="flex mb-7">
              <UserManagementFormLabel label="Status:" htmlFor="status" />
              <div className="grow">
                <Field as="select" name="status" id="status">
                  <option value="">Select Status</option>
                  {userStatusList.map((status) => (
                    <option key={status.key} value={status.key}>
                      {status.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="status" component="div" className="form-error" />
              </div>
            </div>
            <div className="pt-4 text-right">
              <Button
                type="submit"
                label="Add New User"
                disabled={isSubmitting}
                customClass="bg-blue text-white max-w-[350px] w-full"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UserManagementAddForm;
