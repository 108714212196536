import HorizontalRule from '../../../horizontal-rule';
import { TemplateHeaderType } from './types';

function TemplateHeader({ title, customClass = 'no-custom' }: TemplateHeaderType) {
  return (
    <div className={`${customClass}`}>
      <div
        className={`inline-block rounded-t-md bg-black text-white font-bold text-lg text-center py-0.5 px-4 min-w-[150px] ml-2`}
      >
        {title}
      </div>
      <HorizontalRule />
    </div>
  );
}

export default TemplateHeader;
