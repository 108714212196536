import { useContext } from 'react';
import { ModalContext, UserContext } from '../context';

export const useUserContext = () => {
  const contextValue = useContext(UserContext);
  return contextValue;
};

export const useModalContext = () => {
  const contextValue = useContext(ModalContext);
  return contextValue;
};
