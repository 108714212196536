import { Form, Formik } from 'formik';
import { handleAxiosError } from '../../../../../common/helper';
import axiosInstance from '../../../../../common/interceptors';
import Button from '../../../../button';
import { useModalContext } from '../../../../../common/hooks';
import { ActivityDeleteButtonType } from './types';

function ActivityDeleteButton({ activityId, deleteCallback = () => {} }: ActivityDeleteButtonType) {
  const modalContextValue = useModalContext();

  const deleteActivity = async () => {
    try {
      await axiosInstance.delete(`/activity/delete?id=${activityId}`);
      deleteCallback(activityId);
      modalContextValue.hideModal();
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    }
  };

  const getConfirmModalBody = () => {
    return (
      <div>
        <div>
          <div className="text-center font-bold">
            Are you sure you want to delete this activity? Once deleted, this action cannot be
            undone ?
          </div>
        </div>
        <div className="text-center mt-5 mb-3">
          <Formik initialValues={{ delete: true }} onSubmit={deleteActivity}>
            {({ isSubmitting }) => (
              <Form autoComplete="off" role="form">
                <Button
                  type="submit"
                  label="Delete"
                  customClass="bg-red text-white mx-1 min-w-[120px]"
                  disabled={isSubmitting}
                />
                <Button
                  type="button"
                  label="Cancel"
                  handleClick={modalContextValue.hideModal}
                  customClass="bg-black-light text-white mx-1 min-w-[120px]"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  const showConfirmModal = () => {
    modalContextValue.setDataForModal({
      title: 'Delete Activity',
      headerClass: 'bg-red text-white',
      modalBody: getConfirmModalBody,
    });
  };

  return (
    <Button
      type="button"
      label="Delete"
      customClass={`min-w-[150px] bg-red text-white ml-2`}
      handleClick={showConfirmModal}
    />
  );
}

export default ActivityDeleteButton;
