import { UserRolesType, SortOrdersType, ActivityTypesType, TourStatusType } from './types';

export const userRoles: UserRolesType = {
  admin: { key: 'ADMINISTRATOR', label: 'Administrator' },
  superAdmin: { key: 'SUPER_ADMIN', label: 'Super Admin' },
};

export const userRoleList: { [key: string]: any }[] = Object.values(userRoles);

export const userRolesKeyBased = userRoleList.reduce((accumulator, currentValue) => {
  return { ...accumulator, [currentValue.key]: currentValue.label };
}, {});

export const userStatus = {
  active: { key: 'ACTIVE', label: 'Active' },
  inActive: { key: 'INACTIVE', label: 'Inactive' },
};

export const userStatusList: { [key: string]: any }[] = Object.values(userStatus);

export const userStatusKeyBased = userStatusList.reduce((accumulator, currentValue) => {
  return { ...accumulator, [currentValue.key]: currentValue.label };
}, {});

export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/;

export const sortSequenceOrders: SortOrdersType = {
  0: 'asc',
  1: 'desc',
};

export const sortTimeOrders: SortOrdersType = {
  0: 'desc',
  1: 'asc',
};

export const appEnvs = {
  prod: 'prod',
  uat: 'uat',
  test: 'test',
  dev: 'dev',
};

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const appEnv = process.env.REACT_APP_ENV;

export const generalErrorMessage = 'Something went wrong. Please try again';

export const paginationLimit = 20;

export const activityPreviewModalProps = {
  title: 'Activity Preview',
  headerClass: 'bg-brown-dark text-white',
  modalClass: 'max-w-[900px] bg-white',
};

export const activityTypes: ActivityTypesType = {
  ranchPricingSheet: 'RANCH_PRICING_SHEET',
  hopiArt: 'HOPI_ART',
  generalActivity: 'GENERAL_ACTIVITY',
  badging: 'ASTRONAUT_BADGING_SHEET',
  cattleBranding: 'CATTLE_BRANDING_IRON',
  bisonHide: 'BISON_HIDE',
  bisonMapping: 'SUPERMARKET_ON_FOUR_HOOVES',
  footer: 'TOUR_FOOTER',
};

export const tourStatus: TourStatusType = {
  active: 'ACTIVE',
  inActive: 'INACTIVE',
  pause: 'PAUSE',
};
