import { Navigate } from 'react-router-dom';
import { useUserContext } from '../../../common/hooks';
import { AuthorizationCheckWrapperType } from './types';

function AuthorizationCheckWrapper({ allowedRoles, children }: AuthorizationCheckWrapperType) {
  const { user } = useUserContext();

  return user && allowedRoles.includes(user.role) ? <>{children}</> : <Navigate to="/dashboard" />;
}

export default AuthorizationCheckWrapper;
