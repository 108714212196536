import { instructionalImageDimensions } from '../data';
import bisonHideDefaultImage from '../../../../../assets/images/bison-hide-default.png';
import { getImageUrlFromValue } from '../../../../../common/helper';
import { BisonHideTemplatePreviewType } from './types';

function BisonHideTemplatePreview({ data }: BisonHideTemplatePreviewType) {
  const { name, description, instructions, instructionalImage } = data;
  const instructionalImageSrc = getImageUrlFromValue(instructionalImage);
  return (
    <div className="font-bold">
      <div className="text-26 mb-2 text-center">{name}</div>
      <div className="text-lg mb-7 text-center">{description}</div>
      <div
        className="font-normal text-base mb-7"
        dangerouslySetInnerHTML={{ __html: instructions }}
        data-testid="template-bh-preview-instructions"
      />
      <div className="mb-7">
        <div
          style={{
            width: `${instructionalImageDimensions.width}px`,
            height: `${instructionalImageDimensions.height}px`,
          }}
          className="overflow-hidden"
        >
          <img src={instructionalImageSrc} alt="Instructional" className="w-100 h-auto" />
        </div>
      </div>
      <div className="border-2 bg-yellow-mid">
        <img src={bisonHideDefaultImage} alt="Bison Hide" className="w-100 h-auto" />
      </div>
    </div>
  );
}

export default BisonHideTemplatePreview;
