import Quill from 'quill';
import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import { align, defaultFontFamily, defaultFontSize, fonts, sizes, toolbarOptions } from './data';
import { QuillEditorPropsType } from './types';

const Font: any = Quill.import('attributors/style/font');
Font.whitelist = fonts;
Quill.register(Font, true);

const Size: any = Quill.import('attributors/style/size');
Size.whitelist = sizes;
Quill.register(Size, true);

const Align: any = Quill.import('attributors/style/align');
Align.whitelist = align;
Quill.register(Align, true);

const QuillEditor = forwardRef<Quill | null, QuillEditorPropsType>(
  ({ id, name, initialValue, onTextChange, onBlur }, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const onTextChangeRef = useRef(onTextChange);
    const initialValueRef = useRef(initialValue);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
    });

    useEffect(() => {
      const container = containerRef.current;

      if (!container) return;

      const editorContainer = container.appendChild(container.ownerDocument.createElement('div'));
      editorContainer.style.fontSize = defaultFontSize;
      editorContainer.style.fontFamily = defaultFontFamily;

      const quill = new Quill(editorContainer, {
        theme: 'snow',
        modules: {
          toolbar: toolbarOptions,
        },
      });

      const quillRef = ref as React.MutableRefObject<Quill | null>;
      quillRef.current = quill;

      const blurListener = (e: any) => {
        onBlur(e);
      };

      const editorRef = quillRef.current.root;
      editorRef.setAttribute('name', name);
      editorRef.setAttribute('id', id);
      editorRef.setAttribute('data-testid', id);
      editorRef.addEventListener('blur', blurListener);

      if (initialValueRef.current) {
        quill.setContents(quill.clipboard.convert({ html: initialValueRef.current }));
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
      });

      return () => {
        editorRef.removeEventListener('blur', blurListener);
        quillRef.current = null;
        container.innerHTML = '';
      };
    }, [name, onBlur, ref]);

    return <div ref={containerRef}></div>;
  }
);

QuillEditor.displayName = 'Editor';

export default QuillEditor;
