import { Link } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import { useUserContext } from '../../common/hooks';
import { list } from './data';

function DashboardView() {
  const contextValue = useUserContext();

  return (
    <div>
      <div className="flex flex-wrap -mx-4">
        {list.map((item) => (
          <Fragment key={item.id}>
            {item.restriction ? (
              contextValue.user && item.restriction.includes(contextValue.user.role) ? (
                <DashboardLink details={item} />
              ) : null
            ) : (
              <DashboardLink details={item} />
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

const DashboardLink = ({ details }: { details: { name: string; link: string } }) => (
  <Link
    className="inline-block bg-brown-dark text-white font-bold px-8 py-3 mb-8 rounded mx-4 text-center min-w-[252px]"
    to={details.link}
  >
    {details.name}
  </Link>
);
export default DashboardView;
