import { useState } from 'react';
import SectionHeader from '../../components/section/header';
import ModalContextWrapper from '../../components/wrapper/modal-context';
import SurveyResultDailyList from '../../components/survey-results/list/daily';
import SurveyResultMonthlyList from '../../components/survey-results/list/monthly';
import { views } from './data';

function SurveyResultsView() {
  const [selectedView, setSelectedView] = useState<'daily' | 'monthly'>('daily');

  const handleTabClick = (event: any) => {
    setSelectedView(event.target.name);
  };
  return (
    <div>
      <SectionHeader title="Survey Results Manager" />
      <div className="my-6">
        <ModalContextWrapper>
          <div>
            {views.map((view, index) => (
              <button
                key={view.id}
                name={view.key}
                className={`font-bold text-lg inline-block rounded-t-md bg-brown-dark text-white text-center py-1.5 px-4 min-w-[110px] ${index === 0 ? 'ml-8' : 'ml-2'} ${view.key === selectedView ? 'opacity-1' : 'opacity-75'}`}
                onClick={handleTabClick}
              >
                {view.name}
              </button>
            ))}
            {selectedView === 'daily' ? <SurveyResultDailyList /> : <SurveyResultMonthlyList />}
          </div>
        </ModalContextWrapper>
      </div>
    </div>
  );
}

export default SurveyResultsView;
