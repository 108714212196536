import { generalErrorMessage } from './data';
import { GetImageDimesnionsType, MarkFieldTouchedType, ValidateImageDimensionsType } from './types';

export const handleAxiosError = (error: any) => {
  if (error.response) {
    /*
    The request was made and the server responded with a status code
    that falls out of the range of 2xx 
    */
    const { status, data } = error.response;
    return { status, message: data ? data.message : error.message };
  } else if (error.request) {
    /*
    The request was made but no response was received `error.request` is an instance of XMLHttpRequest
    in the browser and an instance of http.ClientRequest in node.js
    */
    return { status: 'ERR_BAD_REQUEST', message: generalErrorMessage };
  } else {
    // Something happened in setting up the request that triggered an Error
    const { code } = error;
    return { status: code, message: generalErrorMessage };
  }
};

export const handleEnterKeyPress = (callback: () => void) => {
  return (event: { key: string }) => {
    if (event.key === 'Enter') {
      callback();
    }
  };
};

export const handleLogout = () => {
  localStorage.clear();
  window.location.reload();
};

export const markFieldTouched: MarkFieldTouchedType = async (name, formHelpers) => {
  try {
    const fieldMeta = formHelpers.getFieldMeta(`${name}`);
    if (!fieldMeta.touched) {
      await formHelpers.setFieldTouched(`${name}`, true);
    }
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }
};

export const validateImageDimensions: ValidateImageDimensionsType = async (
  file,
  width,
  height,
  _getImageDimesnions = getImageDimesnions
) => {
  try {
    const imageDimensions = await _getImageDimesnions(file);

    if (imageDimensions.width === width && imageDimensions.height === height) {
      return true;
    }
    return false;
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log('error', error);
    return false;
  }
};

export const getImageDimesnions: GetImageDimesnionsType = (file) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = function () {
      resolve({
        width: image.width,
        height: image.height,
      });
    };
    image.onerror = function () {
      reject(new Error('Failed to load image'));
    };
    image.src = URL.createObjectURL(file);
  });
};

export const getImageUrlFromValue = (image: string | File) => {
  return typeof image === 'string' ? image : URL.createObjectURL(image);
};

export const formatTourActivityImages = (
  imageList: { id: string; name: string; image: string }[]
) => {
  const images = imageList ? [...imageList] : [];
  while (images.length < 8) {
    images.push({ id: '', name: '', image: '' });
  }
  return images;
};
