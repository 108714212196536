import TemplateRPSImage from '../../../../assets/images/activity-template-rps.png';
import TemplateHAImage from '../../../../assets/images/activity-template-ha.png';
import TemplateGAImage from '../../../../assets/images/activity-template-ga.png';
import TemplateBadgeImage from '../../../../assets/images/activity-template-badge.png';
import TemplateCBImage from '../../../../assets/images/activity-template-cb.png';
import TemplateBHImage from '../../../../assets/images/activity-template-bh.png';
import TemplateBMImage from '../../../../assets/images/activity-template-bm.png';
import TemplateFooterImage from '../../../../assets/images/activity-template-footer.png';
import { activityTypes } from '../../../../common/data';

export const templateList = [
  { id: 'template-rps', value: activityTypes.ranchPricingSheet, imgSrc: TemplateRPSImage },
  { id: 'template-ha', value: activityTypes.hopiArt, imgSrc: TemplateHAImage },
  { id: 'template-ga', value: activityTypes.generalActivity, imgSrc: TemplateGAImage },
  { id: 'template-badge', value: activityTypes.badging, imgSrc: TemplateBadgeImage },
  { id: 'template-cb', value: activityTypes.cattleBranding, imgSrc: TemplateCBImage },
  { id: 'template-bh', value: activityTypes.bisonHide, imgSrc: TemplateBHImage },
  { id: 'template-bm', value: activityTypes.bisonMapping, imgSrc: TemplateBMImage },
  { id: 'template-footer', value: activityTypes.footer, imgSrc: TemplateFooterImage },
];
