import { activityPreviewModalProps } from '../../../../../common/data';
import { useModalContext } from '../../../../../common/hooks';
import Button from '../../../../button';
import TourActivityPreview from '../../../activity/preview';
import { ActivityPreviewButtonType } from './types';

function ActivityPreviewButton({ activityType, data }: ActivityPreviewButtonType) {
  const modalContextValue = useModalContext();
  const showPreview = () => {
    const getModalBody = () => {
      return (
        <div className="max-h-[85vh] overflow-auto p-5">
          <TourActivityPreview type={activityType} values={data} />
        </div>
      );
    };

    modalContextValue.setDataForModal({ ...activityPreviewModalProps, modalBody: getModalBody });
  };

  return (
    <Button
      type="button"
      label="Preview"
      customClass={`min-w-[150px] bg-orange-dark text-white ml-2`}
      handleClick={showPreview}
    />
  );
}

export default ActivityPreviewButton;
