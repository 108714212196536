import { object, string } from 'yup';
import { emailRegex, userRoles, userStatus } from '../../../../common/data';

const { admin, superAdmin } = userRoles;
const { active, inActive } = userStatus;

export const addUserSchema = object({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  email: string().required('Required').matches(emailRegex, 'Invalid email address'),
  password: string()
    .required('Required')
    .min(10, 'Password must be at least 10 characters')
    .matches(/(?:.*[A-Z]){2,}/, 'Password must contain 2 capital letters')
    .matches(/(?:.*[0-9]){3,}/, 'Password must contain 3 digits'),
  role: string()
    .required('Required')
    .oneOf(
      [admin.key, superAdmin.key],
      'Role must be one of the following values: Administrator, Super Admin'
    ),
  status: string()
    .required('Required')
    .oneOf(
      [active.key, inActive.key],
      'Role must be one of the following values: Active, Inactive'
    ),
});

// .matches(/^(?=(.*[A-Z]){2,})(?=(.*\d){3})(?=.{10,}$).*/, 'test')
