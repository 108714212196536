import wsmVeertWhiteLogo from '../../assets/images/wsm-vert-white-logo.png';

function Footer() {
  return (
    <div className="flex bg-brown-dark text-white px-4 sm:px-6 py-6 ">
      <div className="grow">
        <div className="mb-6">
          <div className="font-bold text-26 mb-1">
            Western Spirit: Scottdale's Museum of the West
          </div>
          <div className="font-bold">3830 N. Marshall Way</div>
          <div className="font-bold">Scottdale, Arizona 85251</div>
          <div className="font-bold">1-480-686-9539</div>
          <div>
            &#169; 2015 - 2024 - Western Spirit: Scottdale's Museum of the West -- All Rights
            Reserved
          </div>
        </div>
        <div className="font-bold">
          <span className="text-slate-200">Powered by: </span>
          <span className="underline">datatitan</span>
        </div>
      </div>
      <div>
        <img className="w-[107px] h-auto" src={wsmVeertWhiteLogo} alt="Western Spirit Logo" />
      </div>
    </div>
  );
}

export default Footer;
