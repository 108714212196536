import { Link } from 'react-router-dom';
import { AppUserType } from '../../common/types';
import wsmsmHorizWhiteLogo from '../../assets/images/wsm-sm-horiz-white-logo.png';
import axiosInstance from '../../common/interceptors';
import { apiBaseUrl } from '../../common/data';
import { handleAxiosError, handleLogout } from '../../common/helper';
import { useModalContext } from '../../common/hooks';

function Header({ user }: { user: AppUserType }) {
  const modalContextValue = useModalContext();
  const handleSignOut = async () => {
    try {
      await axiosInstance.post(`${apiBaseUrl}/auth/logout`);
      handleLogout();
    } catch (error: any) {
      const errorResponse = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorResponse.message);
    }
  };
  return (
    <div className="flex bg-brown-dark text-white font-bold px-4 sm:px-6 py-4 ">
      <div className="grow flex items-center">
        {user && (
          <>
            <Link to="/dashboard">Dashboard</Link>
            <span className="mx-3">|</span>
            <button type="button" onClick={handleSignOut}>
              Sign Out
            </button>
          </>
        )}
      </div>
      <div>
        <img className="w-[250px] h-auto" src={wsmsmHorizWhiteLogo} alt="Western Spirit Logo" />
      </div>
    </div>
  );
}

export default Header;
