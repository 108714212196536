export const defaultFontSize = '16px';
export const defaultFontFamily = 'Carlito';
export const fonts = [false, 'Glyddon'];
export const sizes = ['14px', false, '18px', '20px', '24px'];
export const align = [false, 'center', 'right'];

export const toolbarOptions = [
  [{ font: fonts }],
  [{ size: sizes }],
  ['bold', 'italic', 'underline'],
  [{ color: [] }],
  [{ align: align }],
];
