import { Form, Formik, FormikHelpers } from 'formik';
import Button from '../../../button';
import QuillEditorField from '../../../quill-editor/form-field';

import { activityTypes } from '../../../../common/data';
import ImageDropboxField from '../../../dropbox/image/form-field';
import wsmsmLogo from '../../../../assets/images/wsm-sm-logo.png';
import footerDefaultImage from '../../../../assets/images/footer-default.png';
import axiosInstance from '../../../../common/interceptors';
import ActivityPreviewButton from '../button/preview';
import ActivityDeleteButton from '../button/delete';
import { FooterTemplateType, FormValuesType } from './types';
import { formSchema } from './schema';
import { instructionalImageDimensions } from './data';

function FooterTemplate({
  tourId,
  activityId,
  mode,
  initialValues = {
    footerTop: '',
    instructionalImage: '',
    footerContent: '',
  },
  actionHelpers,
}: FooterTemplateType) {
  const handleSubmit = async (
    values: FormValuesType,
    formikHelpers: FormikHelpers<FormValuesType>
  ) => {
    try {
      const { footerTop, footerContent, instructionalImage } = values;
      const formData = new FormData();
      formData.append('topFooter', footerTop);
      if (instructionalImage instanceof File) {
        formData.append('instructionImageFile', instructionalImage);
      }
      formData.append('footerContent', footerContent);
      if (mode === 'add') {
        formData.append('tourId', tourId);
        formData.append('type', activityTypes.footer);
        await axiosInstance.post('/activity', formData);
      } else {
        const response = await axiosInstance.patch(`/activity/update?id=${activityId}`, formData);
        const { instructionImageUrl } = response.data.activity;
        formikHelpers.setFieldValue(
          'instructionalImage',
          instructionImageUrl ? instructionImageUrl : ''
        );
      }
      actionHelpers.submitCallback();
    } catch (error) {
      actionHelpers.errorCallback(error);
    }
  };

  return (
    <div className="font-bold">
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, dirty, isValid }) => (
          <Form autoComplete="off">
            <div className="template-form-container">
              <div className="flex mb-7">
                <label className="min-w-[175px] mr-4" />
                <div className="w-[350px] h-auto m-auto">
                  <img src={wsmsmLogo} alt="Logo" className="w-100 h-auto" />
                </div>
              </div>
              <div className="flex mb-7">
                <label
                  className="min-w-[175px] mr-4 h-12 flex items-center justify-end"
                  htmlFor="template-footer-top"
                >
                  Top of Footer:
                </label>
                <div className="grow font-normal">
                  <QuillEditorField name="footerTop" editorId="template-footer-top" />
                </div>
              </div>
              <div className="flex mb-7 overflow-auto">
                <label
                  className="min-w-[175px] max-w-[175px] mr-4 h-[175px] flex flex-col items-end justify-center"
                  htmlFor="template-ga-instructional-image"
                >
                  Instructional Image:
                  <span className="block text-right mt-2 text-sm">
                    Drag & Drop or <br /> Click to select image
                  </span>
                  <span className="block text-right mt-5 text-sm">
                    {`Image dimensions must be exactly ${instructionalImageDimensions.width} x ${instructionalImageDimensions.height} pixels, in JPG or PNG format only`}
                  </span>
                </label>
                <div className="grow">
                  <ImageDropboxField
                    dropboxId="template-ga-instructional-image"
                    name="instructionalImage"
                    width={instructionalImageDimensions.width}
                    height={instructionalImageDimensions.height}
                    disabled={isSubmitting}
                  />
                  <div className="mt-3">
                    <img src={footerDefaultImage} alt="Default" />
                  </div>
                </div>
              </div>

              <div className="flex mb-7">
                <label
                  className="min-w-[175px] mr-4 h-12 flex items-center justify-end"
                  htmlFor="template-footer-content"
                >
                  Footer Content:
                </label>
                <div className="grow font-normal">
                  <QuillEditorField name="footerContent" editorId="template-footer-content" />
                </div>
              </div>
              <div className="flex pt-7">
                <label className="min-w-[175px] mr-4" />
                <div>
                  <Button
                    type="submit"
                    label={mode === 'edit' ? 'Update' : 'Add'}
                    disabled={isSubmitting}
                    customClass="bg-blue text-white min-w-[150px]"
                  />
                  {((mode === 'add' && dirty && isValid) || (mode === 'edit' && isValid)) && (
                    <ActivityPreviewButton activityType={activityTypes.footer} data={values} />
                  )}
                  {mode === 'edit' && (
                    <ActivityDeleteButton
                      activityId={activityId}
                      deleteCallback={actionHelpers.deleteCallback}
                    />
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FooterTemplate;
