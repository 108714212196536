import { FileRejection, useDropzone } from 'react-dropzone';
import { useState } from 'react';
import { ImageIcon } from '../../svg';
import { markFieldTouched } from '../../../common/helper';
import { ImageDropboxType } from './types';

const ImageDropbox = ({
  id,
  name,
  width,
  height,
  disabled,
  formHelpers,
  currentImage,
}: ImageDropboxType) => {
  const [initialImage] = useState(currentImage);
  const previewImageSrc =
    typeof currentImage === 'string' ? currentImage : URL.createObjectURL(currentImage);

  const handleError = (error: Error) => {
    // eslint-disable-next-line no-console
    console.log('Image dropbox error', error);
  };

  const handleDropAccepted = async (files: File[]) => {
    await markFieldTouched(name, formHelpers);
    await formHelpers.setFieldValue(name, files[0]);
  };

  const handleDropRejected = async (fileRejections: FileRejection[]) => {
    await markFieldTouched(name, formHelpers);
  };

  const handleFileDialogCancel = async () => {
    await markFieldTouched(name, formHelpers);
  };

  const handleRemoveClick = async (event: any) => {
    event.stopPropagation();
    try {
      await formHelpers.setFieldValue(name, initialImage);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Image remove error', error);
    }
  };

  const dropzoneOptions = {
    accept: { 'image/jpeg': [], 'image/png': [] },
    disabled,
    maxFiles: 1,
    multiple: false,
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
    onError: handleError,
    onFileDialogCancel: handleFileDialogCancel,
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneOptions);

  return (
    <div>
      <div
        {...getRootProps({
          id,
          className: 'inline-block p-2 border border-gray-argent bg-gray-light rounded-md relative',
        })}
      >
        <input {...getInputProps({ title: name })} />
        <div
          style={{ width: `${width}px`, height: `${height}px` }}
          className={`flex flex-col items-center justify-center font-bold overflow-hidden`}
        >
          {currentImage ? (
            <img src={previewImageSrc} alt="preview" className="w-100 h-auto" />
          ) : (
            <>
              <div className="text-center mb-3">
                {isDragActive ? (
                  <span>Drop the image here!</span>
                ) : (
                  <>
                    <span>Drag & Drop Image here</span> <div>or</div>{' '}
                    <span>Click to select image</span>
                  </>
                )}
              </div>
              <div>
                <ImageIcon width={60} height={60} />
              </div>
            </>
          )}
        </div>
        {typeof currentImage !== 'string' && (
          <button
            type="button"
            disabled={disabled}
            onClick={handleRemoveClick}
            className="absolute z-10 top-1 right-1 flex items-center justify-center p-1 w-[26px] h-[26px] font-bold rounded-full border-2 bg-black text-white border-gray-dark"
          >
            X
          </button>
        )}
      </div>
    </div>
  );
};

export default ImageDropbox;
