import { ErrorMessage, Field, Form, Formik } from 'formik';
import UserManagementFormLabel from '../label';
import Button from '../../../button';
import axiosInstance from '../../../../common/interceptors';
import { useModalContext } from '../../../../common/hooks';
import { handleAxiosError } from '../../../../common/helper';
import { UserManagementUpdatePasswordFormType } from './types';
import { updatePasswordSchema } from './schema';

function UserManagementUpdatePasswordForm({ user }: UserManagementUpdatePasswordFormType) {
  const modalContextValue = useModalContext();
  const handleSubmit = async (values: any, formikHelpers: { resetForm: () => void }) => {
    try {
      await axiosInstance.patch(`/user/update/password?userId=${user.id}`, {
        password: values.newPassword,
      });
      formikHelpers.resetForm();
      modalContextValue.setOutcomeModal('success', 'Password updated successfully!');
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    }
  };

  return (
    <div className="font-bold mt-10">
      <div className="bg-gray-light px-3 py-1 mb-3"> Password Management (Reset)</div>
      <div className="p-4">
        <Formik
          initialValues={{ email: user.email, newPassword: '', reTypePassword: '' }}
          validationSchema={updatePasswordSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isSubmitting }) => (
            <Form autoComplete="off">
              <div className="flex mb-7">
                <UserManagementFormLabel label="New Password:" htmlFor="newPassword" />
                <div className="grow">
                  <Field type="text" name="newPassword" id="newPassword" />
                  <ErrorMessage name="newPassword" component="div" className="form-error" />
                </div>
              </div>
              <div className="flex mb-7">
                <UserManagementFormLabel label="Retype Password:" htmlFor="reTypePassword" />
                <div className="grow">
                  <Field type="text" name="reTypePassword" id="reTypePassword" />
                  <ErrorMessage name="reTypePassword" component="div" className="form-error" />
                  <div className="grow  p-3 bg-gray-light mt-4">
                    <div>
                      Password must be a minimum of (10) characters, and must contain (2) capital
                      letters and (3) digits.
                    </div>

                    <div className="mt-3">
                      <div>Example 1: TerRi453r9X</div>
                      <div>Example 2: Ri58YY3Ox!</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-4 text-right">
                <Button
                  type="submit"
                  label="Update Password"
                  disabled={isSubmitting}
                  customClass="bg-blue text-white max-w-[350px] w-full"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default UserManagementUpdatePasswordForm;
