import Button from '../button';
import { PaginationType } from './types';

function Pagination({ currentPage, totalPages, callback }: PaginationType) {
  const pageList = Array.from(Array(totalPages).keys());
  const handleSelectChange = (event: any) => {
    const pageNumber = parseInt(event.target.value);
    callback(pageNumber);
  };
  const handleBackClick = () => {
    const pageNumber = currentPage - 1;
    if (pageNumber > 0) {
      callback(pageNumber);
    }
  };
  const handleNextClick = () => {
    const pageNumber = currentPage + 1;
    if (pageNumber <= totalPages) {
      callback(pageNumber);
    }
  };

  return (
    <div className="flex justify-center">
      <Button
        type="button"
        label="< Prev"
        customClass={`bg-blue text-white`}
        disabled={currentPage === 1}
        handleClick={handleBackClick}
      />
      <select onChange={handleSelectChange} className="mx-4 w-[80px] min-h-2" value={currentPage}>
        {pageList.map((page) => (
          <option key={page + 1} value={page + 1}>
            {page + 1}
          </option>
        ))}
      </select>
      <Button
        type="button"
        label="Next >"
        customClass={`bg-blue text-white`}
        disabled={currentPage === totalPages}
        handleClick={handleNextClick}
      />
    </div>
  );
}

export default Pagination;
