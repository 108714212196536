import RanchPricingSheetTemplatePreview from '../../template/ranch-pricing-sheet/preview';
import HopiArtTemplatePreview from '../../template/hopi-art/preview';
import GeneralActivityTemplatePreview from '../../template/general-activity/preview';
import BadgingTemplatePreview from '../../template/badging/preview';
import CattleBrandingTemplatePreview from '../../template/cattle-branding/preview';
import BisonHideTemplatePreview from '../../template/bison-hide/preview';
import FooterTemplatePreview from '../../template/footer/preview';
import BisonMappingTemplatePreview from '../../template/bison-mapping/preview';
import { activityTypes } from '../../../../common/data';
import { TourActivityPreviewType } from './types';

function TourActivityPreview({ type, values }: TourActivityPreviewType) {
  const getActivityPreview = () => {
    const props = { data: values };
    switch (type) {
      case activityTypes.ranchPricingSheet:
        return <RanchPricingSheetTemplatePreview {...props} />;
      case activityTypes.hopiArt:
        return <HopiArtTemplatePreview {...props} />;
      case activityTypes.generalActivity:
        return <GeneralActivityTemplatePreview {...props} />;
      case activityTypes.badging:
        return <BadgingTemplatePreview {...props} />;
      case activityTypes.cattleBranding:
        return <CattleBrandingTemplatePreview {...props} />;
      case activityTypes.bisonHide:
        return <BisonHideTemplatePreview {...props} />;
      case activityTypes.bisonMapping:
        return <BisonMappingTemplatePreview {...props} />;
      case activityTypes.footer:
        return <FooterTemplatePreview {...props} />;
      default:
        return <div className="text-center">No preview available</div>;
    }
  };

  return <div>{getActivityPreview()}</div>;
}

export default TourActivityPreview;
