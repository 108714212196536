import { mixed, object } from 'yup';
import { validateImageDimensions } from '../../../../common/helper';
import { instructionalImageDimensions } from './data';

export const formSchema = object({
  instructionalImage: mixed<string | File>().test(
    'valid-image-dimensions',
    `Image dimensions must be exactly ${instructionalImageDimensions.width}w x ${instructionalImageDimensions.height}h`,
    async (file) => {
      if (!file || typeof file === 'string') return true;
      const isValid = await validateImageDimensions(
        file,
        instructionalImageDimensions.width,
        instructionalImageDimensions.height
      );
      return isValid;
    }
  ),
  // footerTop: field-level validation
  // footerContent: field-level validation
});
