import { Fragment } from 'react/jsx-runtime';
import { bisonMappings, instructionalImageDimensions } from '../data';
import bisonMappingDefaultImage from '../../../../../assets/images/bison-mapping-default.png';
import MappingTile from '../mapping-tile';
import { getImageUrlFromValue } from '../../../../../common/helper';
import { BisonMappingTemplatePreviewType } from './types';

function BisonMappingTemplatePreview({ data }: BisonMappingTemplatePreviewType) {
  const { name, description, instructions, instructionalImage } = data;
  const instructionalImageSrc = getImageUrlFromValue(instructionalImage);
  return (
    <div className="font-bold">
      <div className="text-26 mb-2 text-center">{name}</div>
      <div className="text-lg mb-7 text-center">{description}</div>
      <div
        className="font-normal text-base mb-7"
        dangerouslySetInnerHTML={{ __html: instructions }}
        data-testid="template-bm-preview-instructions"
      />
      <div className="mb-7">
        <div
          style={{
            width: `${instructionalImageDimensions.width}px`,
            height: `${instructionalImageDimensions.height}px`,
          }}
          className="overflow-hidden"
        >
          <img src={instructionalImageSrc} alt="Instructional" className="w-100 h-auto" />
        </div>
      </div>
      <div className="bg-yellow-mid mb-7">
        <img src={bisonMappingDefaultImage} alt="Bison Map" className="w-100 h-auto" />
      </div>
      <div>
        <label>Mappings:</label>
        <div className="grow flex flex-wrap mt-2">
          {bisonMappings.map((item) => (
            <Fragment key={item.key}>
              <MappingTile title={item.mapping} />
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BisonMappingTemplatePreview;
