import DrawBox from '../../draw-box';
import { GeneralActivityTemplatePreviewType } from './types';

function GeneralActivityTemplatePreview({ data }: GeneralActivityTemplatePreviewType) {
  const { name, description, instructions } = data;
  return (
    <div className="font-bold">
      <div className="text-26 mb-2 text-center">{name}</div>
      <div className="text-lg mb-7 text-center">{description}</div>
      <div
        className="font-normal text-base mb-7"
        dangerouslySetInnerHTML={{ __html: instructions }}
        data-testid="template-ga-preview-instructions"
      />
      <div>
        <DrawBox title="Student Draw Space" />
      </div>
    </div>
  );
}

export default GeneralActivityTemplatePreview;
