import { ModalType } from './types';

function Modal({
  title,
  modalClass = 'no-custom',
  headerClass = 'no-custom',
  toggleModal,
  children,
}: ModalType) {
  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 flex justify-center items-center bg-backdrop z-50">
      <div
        className={`fixed mx-auto w-full max-w-[500px] shadow-lg rounded-2xl bg-gray-mid ${modalClass}`}
        role="dialog"
      >
        <div className={`flex font-bold px-4 py-3 rounded-t-2xl ${headerClass}`}>
          <div className="grow text-lg">{title}</div>
          <button
            type="button"
            onClick={toggleModal}
            className="flex justify-center items-center w-[28px]"
          >
            X
          </button>
        </div>
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
}

export default Modal;
