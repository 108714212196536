import axios from 'axios';
import { apiBaseUrl, appEnv, appEnvs } from './data';
import { handleLogout } from './helper';

const axiosInstance = axios.create({ baseURL: apiBaseUrl });

axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    /**
     * In case of same-origin, withCredentials is not required
     * if (appEnv !== appEnvs.prod && appEnv !== appEnvs.uat) {config.withCredentials = true;}
     */
    if (appEnv !== appEnvs.test) {
      config.withCredentials = true;
    }

    if (
      config.headers &&
      config.method &&
      !['post', 'put', 'patch', 'delete'].includes(config.method)
    ) {
      config.headers['X-CSRF-Token'] = 'na-token';
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
      handleLogout();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
