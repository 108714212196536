import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../../common/interceptors';
import Button from '../../../button';
import { handleAxiosError } from '../../../../common/helper';
import { useModalContext } from '../../../../common/hooks';
import { addQuestionSchema } from './schema';

function SurveyQuestionsAddForm() {
  const navigate = useNavigate();
  const modalContextValue = useModalContext();

  const handleSubmit = async (values: { question: any }) => {
    try {
      await axiosInstance.post('/survey/questions', {
        question: values.question,
      });
      navigate('/survey-questions');
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    }
  };

  return (
    <div className="font-bold">
      <Formik
        initialValues={{ question: '' }}
        validationSchema={addQuestionSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form autoComplete="off">
            <div className="mb-3">
              <label htmlFor="question">
                Enter Survey Question, then click "Add Survey Question" button
              </label>
              <div className="mt-3">
                <Field type="text" name="question" id="question" as="textarea" maxLength={300} />
                <ErrorMessage name="question" component="div" className="form-error" />
              </div>
            </div>
            <div className="flex">
              <Button
                type="submit"
                label="Add Survey Question"
                disabled={isSubmitting}
                customClass="bg-blue text-white min-w-[200px]"
              />
              <div className="grow text-right">
                Remaining Characters:{' '}
                <span className="text-blue">{300 - values.question.length}</span>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SurveyQuestionsAddForm;
