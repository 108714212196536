import SectionHeader from '../../components/section/header';
import TourList from '../../components/tours/list';
import ModalContextWrapper from '../../components/wrapper/modal-context';

function ToursView() {
  return (
    <div>
      <SectionHeader title="Manage Tours" />
      <div className="my-6">
        <ModalContextWrapper>
          <TourList />
        </ModalContextWrapper>
      </div>
    </div>
  );
}

export default ToursView;
