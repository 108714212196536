import { createContext } from 'react';
import { ModalContextDefaultValueType, UserContextDefaultValueType } from './types';

const userContextDefaultValue: UserContextDefaultValueType = {
  user: null,
  setUser: () => {},
};
export const UserContext = createContext(userContextDefaultValue);

const modalContextDefaultValue: ModalContextDefaultValueType = {
  dataForModal: null,
  setDataForModal: () => {},
  hideModal: () => {},
  setOutcomeModal: () => {},
};
export const ModalContext = createContext(modalContextDefaultValue);
