import { useState } from 'react';
import { SwitchType } from './types';

function Switch({ initalState, id, callback }: SwitchType) {
  const [status, setStatus] = useState(initalState);
  const [disabled, setDisabled] = useState(false);
  const resetStatus = (currentStatus: boolean) => {
    setStatus(!currentStatus);
  };
  const enableButton = () => {
    setDisabled(false);
  };
  const handleClick = () => {
    setStatus(!status);
    setDisabled(true);
    callback(!status, id, { resetStatus, enableButton });
  };
  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={disabled}
      className={`font-bold flex items-center w-[80px] h-[30px] border-2 rounded-2xl relative transition-all ease-in-out duration-300 ${status ? "after:content-['On'] after:absolute after:top-[2px] after:left-[30px] bg-white" : "before:content-['Off'] before:absolute before:top-[2px] before:right-[30px] bg-gray-argent"} ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
    >
      <span
        className={`absolute top-[4px] inline-block rounded-full w-[18px] h-[18px] bg-black  border transition-all ease-in-out duration-300 ${status ? 'border-black left-[6px]' : 'border-white left-[54px]'}`}
      />
    </button>
  );
}

export default Switch;
