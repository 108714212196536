import { MappingTileType } from './types';

function MappingTile({ title }: MappingTileType) {
  return (
    <div className="p-2 border mr-2 mb-2 min-w-[180px] rounded text-center bg-gray-light border-gray-argent ">
      <div className="bg-white p-1.5">{title}</div>
    </div>
  );
}

export default MappingTile;
