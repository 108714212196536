import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import HorizontalRule from '../../../horizontal-rule';
import CountHeader from '../count-header';
import Button from '../../../button';
import { useModalContext } from '../../../../common/hooks';
import { handleAxiosError } from '../../../../common/helper';
import axiosInstance from '../../../../common/interceptors';
import { formSchema } from './schema';

function TourAddName() {
  const navigate = useNavigate();
  const modalContextValue = useModalContext();

  const addTour = async (values: { name: string }) => {
    try {
      const response = await axiosInstance.post('/tour', { name: values.name });
      const tourId = response.data.id;
      navigate(`/tours/add/select-activity-type`, { state: { tourId, from: 'add-name' } });
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    }
  };

  return (
    <div className="font-bold">
      <div className="my-5">
        <CountHeader count={1} title="Name the Tour Package" />
      </div>
      <HorizontalRule />
      <div className="mt-10">
        <Formik initialValues={{ name: '' }} validationSchema={formSchema} onSubmit={addTour}>
          {({ isSubmitting, values }) => (
            <Form autoComplete="off">
              <div className="flex mb-10 items-center">
                <label
                  className="max-w-[330px] pr-5 mr-5 border-r-2 border-gray-argent text-right"
                  htmlFor="tour-name"
                >
                  Tour Package Name: In the field to the right, enter the name of the tour package.
                  Examples might include: "Abe Hays Family Spirit of the West Collection" or "Women
                  of the West: Highlights from the Fran and Ed Elliott Southwest Women Artists
                  Collection ".
                </label>
                <div className="grow">
                  <Field type="text" name="name" id="tour-name" maxLength={50} />

                  <ErrorMessage name="name" component="div" className="form-error" />
                  <div className="text-right m-4">
                    Remaining Characters:{' '}
                    <span className="text-blue">{50 - values.name.length}</span>
                  </div>
                </div>
              </div>
              <HorizontalRule />
              <div className="mt-4">
                <Button
                  type="submit"
                  label="Next"
                  disabled={isSubmitting}
                  customClass="bg-blue text-white mr-2"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default TourAddName;
