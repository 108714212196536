import { instructionalImageDimensions } from '../data';
import hopiVaseDefaultImage from '../../../../../assets/images/hopi-vase-default.png';
import { getImageUrlFromValue } from '../../../../../common/helper';
import { HopiArtTemplatePreviewType } from './types';

function HopiArtTemplatePreview({ data }: HopiArtTemplatePreviewType) {
  const { name, description, instructions } = data;
  const images = data.images.filter((item) => item.image);
  return (
    <div className="font-bold">
      <div className="text-26 mb-2 text-center">{name}</div>
      <div className="text-lg mb-7 text-center">{description}</div>
      <div
        className="font-normal text-base mb-7"
        dangerouslySetInnerHTML={{ __html: instructions }}
        data-testid="template-ha-preview-instructions"
      />
      <div className="mb-7">
        <div className="p-5 border border-gray-argent bg-gray-light rounded-xl">
          <div className="bg-white">
            <img src={hopiVaseDefaultImage} alt="Instructional" />
          </div>
        </div>
      </div>
      <div>
        <label>Draggable Elements:</label>
        <div className="flex flex-wrap">
          {images.map((item) => (
            <div key={item.name} className="mr-9 mt-4">
              <label>{item.name}</label>
              <div
                style={{
                  width: `${instructionalImageDimensions.width}px`,
                  height: `${instructionalImageDimensions.height}px`,
                }}
                className="overflow-hidden mt-1"
              >
                <img
                  src={getImageUrlFromValue(item.image)}
                  alt="Draggable"
                  className="w-100 h-auto"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HopiArtTemplatePreview;
