import { useEffect, useMemo, useState } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { UserContext } from '../../../common/context';
import { AppUserType } from '../../../common/types';
import Header from '../../header';
import Footer from '../../footer';
import axiosInstance from '../../../common/interceptors';
import ModalContextWrapper from '../../wrapper/modal-context';
import { handleLogout } from '../../../common/helper';

function MainLayout() {
  const [user, setUser] = useState<AppUserType>(null);
  const contextValue = useMemo(() => {
    return { user, setUser };
  }, [user]);

  const getAppUserDetails = async () => {
    try {
      const response = await axiosInstance.get(`/user/me`);
      const { user, csrfToken } = response.data;
      setUser({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        role: user.role,
        status: user.status,
        csrfToken,
      });
      axiosInstance.defaults.headers.common['X-CSRF-Token'] = csrfToken;
    } catch (error: any) {
      handleLogout();
    }
  };

  useEffect(() => {
    if (user === null) {
      getAppUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <UserContext.Provider value={contextValue}>
        {user ? (
          <div className="min-h-screen flex flex-col justify-between">
            <ModalContextWrapper>
              <Header user={user} />
            </ModalContextWrapper>
            <div className="grow px-4 sm:px-6 py-6">
              <Outlet />
            </div>
            <Footer />
            <ScrollRestoration />
          </div>
        ) : (
          <div className="font-bold min-h-screen flex items-center justify-center">
            Loading . . .
          </div>
        )}
      </UserContext.Provider>
    </div>
  );
}

export default MainLayout;
