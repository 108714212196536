import { Field } from 'formik';
import { useRef } from 'react';
import QuillEditor from '..';
import { FieldRenderPropsType } from '../../../common/types';
import { QuillEditorFieldType, QuillRefType } from './types';

const QuillEditorField = ({ name, editorId }: QuillEditorFieldType) => {
  const quillRef = useRef<QuillRefType>(null);

  const getEditorTextLength = () => {
    if (!quillRef.current) return 0;
    return quillRef.current.getText().trim().length;
  };

  const validateEditorContent = () => {
    let error;
    if (getEditorTextLength() === 0) {
      error = 'Required';
    }
    return error;
  };

  return (
    <Field name={name} validate={validateEditorContent}>
      {({ field, form, meta }: FieldRenderPropsType) => (
        <div>
          <QuillEditor
            id={editorId}
            name={field.name}
            ref={quillRef}
            initialValue={field.value}
            onBlur={field.onBlur}
            onTextChange={() => {
              const value = quillRef.current?.root.innerHTML;
              form.setFieldValue(field.name, value);
            }}
          />
          {meta.touched && meta.error && (
            <div className="error form-error font-bold">{meta.error}</div>
          )}
        </div>
      )}
    </Field>
  );
};

export default QuillEditorField;
