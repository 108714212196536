import { CounterHeaderType } from './types';

function CountHeader({ count, title }: CounterHeaderType) {
  return (
    <div className="inline-flex font-bold relative px-4 py-0.5 my-3 mx-2 rounded-md min-w-[200px] text-white bg-blue">
      <div className="text-xl rounded-full w-[50px] h-[50px] absolute top-[-11px] left-[-6px] flex items-center justify-center bg-blue">
        {count}
      </div>
      <div className="ml-[35px]">{title}</div>
    </div>
  );
}

export default CountHeader;
