import RanchPricingSheetTemplate from '../template/ranch-pricing-sheet';
import HopiArtTemplate from '../template/hopi-art';
import GeneralActivityTemplate from '../template/general-activity';
import BadgingTemplate from '../template/badging';
import CattleBrandingTemplate from '../template/cattle-branding';
import BisonHideTemplate from '../template/bison-hide';
import BisonMappingTemplate from '../template/bison-mapping';
import FooterTemplate from '../template/footer';
import { activityTypes } from '../../../common/data';
import { TourActivityType } from './types';
import { formatTemplateData } from './helper';

function TourActivity({
  tourId,
  activityId,
  type,
  mode,
  details,
  actionHelpers,
}: TourActivityType) {
  const getActivityTemplate = () => {
    const props = {
      tourId,
      activityId,
      mode,
      ...(mode === 'edit' && { initialValues: formatTemplateData(details) }),
      actionHelpers,
    };
    switch (type) {
      case activityTypes.ranchPricingSheet:
        return <RanchPricingSheetTemplate {...props} />;
      case activityTypes.hopiArt:
        return <HopiArtTemplate {...props} />;
      case activityTypes.generalActivity:
        return <GeneralActivityTemplate {...props} />;
      case activityTypes.badging:
        return <BadgingTemplate {...props} />;
      case activityTypes.cattleBranding:
        return <CattleBrandingTemplate {...props} />;
      case activityTypes.bisonHide:
        return <BisonHideTemplate {...props} />;
      case activityTypes.bisonMapping:
        return <BisonMappingTemplate {...props} />;
      case activityTypes.footer:
        return <FooterTemplate {...props} />;
      default:
        return null;
    }
  };

  return <div>{getActivityTemplate()}</div>;
}

export default TourActivity;
