import { object, string } from 'yup';

export const updatePasswordSchema = object({
  newPassword: string()
    .required('Required')
    .min(10, 'Password must be at least 10 characters')
    .matches(/(?:.*[A-Z]){2,}/, 'Password must contain 2 capital letters')
    .matches(/(?:.*[0-9]){3,}/, 'Password must contain 3 digits'),
  reTypePassword: string()
    .required('Required')
    .test('password-should-match', 'Passwords must match', function (value) {
      return this.parent.newPassword === value;
    }),
});
