import { UserManagementFormLabelType } from './types';

function UserManagementFormLabel({ label, htmlFor }: UserManagementFormLabelType) {
  return (
    <label htmlFor={htmlFor} className="min-w-[150px] mr-4 h-12 flex items-center justify-end">
      {label}
    </label>
  );
}

export default UserManagementFormLabel;
