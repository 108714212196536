import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import Button from '../../../button';
import QuillEditorField from '../../../quill-editor/form-field';
import { activityTypes } from '../../../../common/data';
import ImageDropboxField from '../../../dropbox/image/form-field';
import hopiVaseDefaultImage from '../../../../assets/images/hopi-vase-default.png';
import SectionHeader from '../../../section/header';
import axiosInstance from '../../../../common/interceptors';
import ActivityDeleteButton from '../button/delete';
import ActivityPreviewButton from '../button/preview';
import { formatTourActivityImages } from '../../../../common/helper';
import { FormValuesType, HopiArtTemplateType } from './types';
import { formSchema } from './schema';
import { instructionalImageDimensions } from './data';

function HopiArtTemplate({
  tourId,
  activityId,
  mode,
  initialValues = {
    name: '',
    description: '',
    instructions: '',
    images: [
      { id: '', name: '', image: '' },
      { id: '', name: '', image: '' },
      { id: '', name: '', image: '' },
      { id: '', name: '', image: '' },
      { id: '', name: '', image: '' },
      { id: '', name: '', image: '' },
      { id: '', name: '', image: '' },
      { id: '', name: '', image: '' },
    ],
  },
  actionHelpers,
}: HopiArtTemplateType) {
  const handleSubmit = async (
    values: FormValuesType,
    formikHelpers: FormikHelpers<FormValuesType>
  ) => {
    try {
      const { name, description, instructions, images } = values;

      const formData = new FormData();
      formData.append('name', name);
      formData.append('description', description);
      formData.append('instructions', instructions);
      let index = 0;
      images.forEach((item) => {
        if (item.image) {
          const imageDetails = JSON.stringify({
            name: item.name,
            ...(mode === 'edit' && { id: item.id ? item.id : 'add-image' }),
          });
          formData.append(`image[${index}][details]`, imageDetails);
          if (item.image instanceof File) {
            formData.append(`image[${index}][file]`, item.image);
          }
          index = index + 1;
        }
      });
      if (mode === 'add') {
        formData.append('tourId', tourId);
        formData.append('type', activityTypes.hopiArt);
        await axiosInstance.post('/activity', formData);
      } else {
        const response = await axiosInstance.patch(`/activity/update?id=${activityId}`, formData);
        const formattedImages = formatTourActivityImages(response.data.activity.image);
        formikHelpers.setFieldValue('images', formattedImages);
      }
      actionHelpers.submitCallback();
    } catch (error) {
      actionHelpers.errorCallback(error);
    }
  };

  return (
    <div className="font-bold">
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, dirty, isValid, errors, touched }) => (
          <Form autoComplete="off">
            <div className="template-form-container">
              <div className="flex mb-7">
                <label
                  className="min-w-[175px] mr-4 h-12 flex items-center justify-end"
                  htmlFor="template-ha-name"
                >
                  Activity Name:
                </label>
                <div className="grow">
                  <Field type="text" name="name" id="template-ha-name" />
                  <ErrorMessage name="name" component="div" className="form-error" />
                </div>
              </div>
              <div className="flex mb-7">
                <label
                  className="min-w-[175px] mr-4 h-12 flex items-center justify-end"
                  htmlFor="template-ha-description"
                >
                  Activity Description:
                </label>
                <div className="grow">
                  <Field type="text" name="description" id="template-ha-description" />
                  <ErrorMessage name="description" component="div" className="form-error" />
                </div>
              </div>
              <div className="flex mb-7">
                <label
                  className="min-w-[175px] mr-4 h-12 flex items-center justify-end"
                  htmlFor="template-ha-instructions"
                >
                  Instructions:
                </label>
                <div className="grow font-normal">
                  <QuillEditorField name="instructions" editorId="template-ha-instructions" />
                </div>
              </div>
              <div className="flex">
                <label className="min-w-[175px] mr-4 h-12 flex flex-col items-end justify-center">
                  Instructional Image:
                </label>
                <div className="grow">
                  <div className="p-5 border border-gray-argent bg-gray-light rounded-xl">
                    <div className="bg-white">
                      <img src={hopiVaseDefaultImage} alt="Instructional" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-9 mb-9">
                <label className="min-w-[175px] mr-4" />
                <div className="grow">
                  <SectionHeader
                    title="Draggable Elements:"
                    textSize="text-base"
                    textTransform="none"
                  />
                </div>
              </div>
              <div className="overflow-auto mb-7">
                <div className="mb-5">
                  <div className="flex mb-5">
                    <label
                      className="min-w-[175px] mr-4 h-12 flex flex-col items-end justify-center"
                      htmlFor="template-ha-images-zero-name"
                    >
                      Name:
                    </label>
                    <div className="grow flex">
                      <div className="min-w-[188px] mr-10">
                        <Field
                          type="text"
                          name="images[0].name"
                          id="template-ha-images-zero-name"
                        />
                        <ErrorMessage
                          name="images[0].name"
                          component="div"
                          className="form-error"
                        />
                      </div>
                      <div className="min-w-[188px] mr-10">
                        <Field type="text" name="images[1].name" id="template-ha-images-one-name" />
                        <ErrorMessage
                          name="images[1].name"
                          component="div"
                          className="form-error"
                        />
                      </div>
                      <div className="min-w-[188px] mr-10">
                        <Field type="text" name="images[2].name" id="template-ha-images-two-name" />
                        <ErrorMessage
                          name="images[2].name"
                          component="div"
                          className="form-error"
                        />
                      </div>
                      <div className="min-w-[188px]">
                        <Field
                          type="text"
                          name="images[3].name"
                          id="template-ha-images-three-name"
                        />
                        <ErrorMessage
                          name="images[3].name"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <label
                      className="max-w-[175px] min-w-[175px] mr-4 h-[175px] flex flex-col items-end justify-center"
                      htmlFor="template-ha-images-zero-image"
                    >
                      Image:
                      <span className="block text-right mt-2 text-sm">
                        Drag & Drop or <br /> Click to select image
                      </span>
                      <span className="block text-right mt-5 text-sm">
                        {`Image dimensions must be exactly ${instructionalImageDimensions.width} x ${instructionalImageDimensions.height} pixels, in JPG or PNG format only`}
                      </span>
                    </label>
                    <div className="grow flex">
                      <div className="mr-10">
                        <ImageDropboxField
                          dropboxId="template-ha-images-zero-image"
                          name="images[0].image"
                          width={instructionalImageDimensions.width}
                          height={instructionalImageDimensions.height}
                          disabled={isSubmitting}
                        />
                      </div>
                      <div className="mr-10">
                        <ImageDropboxField
                          dropboxId="template-ha-images-one-image"
                          name="images[1].image"
                          width={instructionalImageDimensions.width}
                          height={instructionalImageDimensions.height}
                          disabled={isSubmitting}
                        />
                      </div>
                      <div className="mr-10">
                        <ImageDropboxField
                          dropboxId="template-ha-images-two-image"
                          name="images[2].image"
                          width={instructionalImageDimensions.width}
                          height={instructionalImageDimensions.height}
                          disabled={isSubmitting}
                        />
                      </div>
                      <div>
                        <ImageDropboxField
                          dropboxId="template-ha-images-three-image"
                          name="images[3].image"
                          width={instructionalImageDimensions.width}
                          height={instructionalImageDimensions.height}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex mb-5">
                    <label
                      className="min-w-[175px] mr-4 h-12 flex flex-col items-end justify-center"
                      htmlFor="template-ha-images-four-name"
                    >
                      Name:
                    </label>
                    <div className="grow flex">
                      <div className="min-w-[188px] mr-10">
                        <Field
                          type="text"
                          name="images[4].name"
                          id="template-ha-images-four-name"
                        />
                        <ErrorMessage
                          name="images[4].name"
                          component="div"
                          className="form-error"
                        />
                      </div>
                      <div className="min-w-[188px] mr-10">
                        <Field
                          type="text"
                          name="images[5].name"
                          id="template-ha-images-five-name"
                        />
                        <ErrorMessage
                          name="images[5].name"
                          component="div"
                          className="form-error"
                        />
                      </div>
                      <div className="min-w-[188px] mr-10">
                        <Field type="text" name="images[6].name" id="template-ha-images-six-name" />
                        <ErrorMessage
                          name="images[6].name"
                          component="div"
                          className="form-error"
                        />
                      </div>
                      <div className="min-w-[188px]">
                        <Field
                          type="text"
                          name="images[7].name"
                          id="template-ha-images-seven-name"
                        />
                        <ErrorMessage
                          name="images[7].name"
                          component="div"
                          className="form-error"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex">
                    <label
                      className="max-w-[175px] min-w-[175px] mr-4 h-[175px] flex flex-col items-end justify-center"
                      htmlFor="template-ha-images-four-image"
                    >
                      Image:
                      <span className="block text-right mt-2 text-sm">
                        Drag & Drop or <br /> Click to select image
                      </span>
                      <span className="block text-right mt-5 text-sm">
                        {`Image dimensions must be exactly ${instructionalImageDimensions.width} x ${instructionalImageDimensions.height} pixels, in JPG or PNG format only`}
                      </span>
                    </label>
                    <div className="grow flex">
                      <div className="mr-10">
                        <ImageDropboxField
                          dropboxId="template-ha-images-four-image"
                          name="images[4].image"
                          width={instructionalImageDimensions.width}
                          height={instructionalImageDimensions.height}
                          disabled={isSubmitting}
                        />
                      </div>
                      <div className="mr-10">
                        <ImageDropboxField
                          dropboxId="template-ha-images-five-image"
                          name="images[5].image"
                          width={instructionalImageDimensions.width}
                          height={instructionalImageDimensions.height}
                          disabled={isSubmitting}
                        />
                      </div>
                      <div className="mr-10">
                        <ImageDropboxField
                          dropboxId="template-ha-images-six-image"
                          name="images[6].image"
                          width={instructionalImageDimensions.width}
                          height={instructionalImageDimensions.height}
                          disabled={isSubmitting}
                        />
                      </div>
                      <div>
                        <ImageDropboxField
                          dropboxId="template-ha-images-seven-image"
                          name="images[7].image"
                          width={instructionalImageDimensions.width}
                          height={instructionalImageDimensions.height}
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex mt-1">
                  <label className="min-w-[175px] mr-4" />
                  {errors.images && typeof errors.images === 'string' && (
                    <ErrorMessage name="images" component="div" className="form-error" />
                  )}
                </div>
              </div>
              <div className="flex pt-7">
                <label className="min-w-[175px] mr-4" />
                <div>
                  <Button
                    type="submit"
                    label={mode === 'edit' ? 'Update' : 'Add'}
                    disabled={isSubmitting}
                    customClass="bg-blue text-white min-w-[150px]"
                  />
                  {((mode === 'add' && dirty && isValid) || (mode === 'edit' && isValid)) && (
                    <ActivityPreviewButton activityType={activityTypes.hopiArt} data={values} />
                  )}
                  {mode === 'edit' && (
                    <ActivityDeleteButton
                      activityId={activityId}
                      deleteCallback={actionHelpers.deleteCallback}
                    />
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default HopiArtTemplate;
