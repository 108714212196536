import { useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import SectionHeader from '../../components/section/header';
import SurveyQuestionList from '../../components/survey-questions/list';
import ModalContextWrapper from '../../components/wrapper/modal-context';

function SurveyQuestionsView() {
  const navigate = useNavigate();

  const addNewSurveyQuestion = () => {
    navigate('/survey-questions/add');
  };

  return (
    <div>
      <SectionHeader title="Survey Questions" />
      <div className="my-6 flex">
        <div className="mr-4">
          <Button
            type="button"
            label="Add New Question"
            handleClick={addNewSurveyQuestion}
            customClass="inline-block bg-blue text-white min-w-[200px]"
          />
        </div>
        <div className="grow">
          <ModalContextWrapper>
            <SurveyQuestionList />
          </ModalContextWrapper>
        </div>
      </div>
    </div>
  );
}

export default SurveyQuestionsView;
