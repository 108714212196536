import { ButtonType } from './types';

function Button({
  type,
  label,
  disabled = false,
  customClass = 'no-custom',
  handleClick,
}: ButtonType) {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`rounded-md py-1.5 px-2 shadow-md min-w-[100px] font-bold ${customClass} ${disabled ? 'opacity-75 cursor-not-allowed' : 'not-disabled'}`}
      onClick={handleClick}
    >
      {label}
    </button>
  );
}

export default Button;
