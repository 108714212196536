import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { RequestStatusType, SwitchHelpersType } from '../../../common/types';
import axiosInstance from '../../../common/interceptors';
import Switch from '../../switch';
import { useModalContext } from '../../../common/hooks';
import Button from '../../button';
import { ArrowUpDownIcon } from '../../svg';
import SurveyItemDeleteButton from '../../survey-item-delete-button';
import { handleAxiosError } from '../../../common/helper';
import { sortSequenceOrders } from '../../../common/data';
import { QuestionsType } from './types';

function SurveyQuestionList() {
  const modalContextValue = useModalContext();
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('request');
  const [questions, setQuestions] = useState<QuestionsType>([]);
  const [filterParams, setFilterParams] = useState({
    sortBy: 'question',
    sortOrder: 0,
  });

  const getQuestions = async () => {
    setRequestStatus('request');
    try {
      const { sortBy, sortOrder } = filterParams;
      const response = await axiosInstance.get(
        `/survey/questions?offset=0&limit=100&sortBy=${sortBy}&sortOrder=${sortSequenceOrders[sortOrder]}`
      );
      setQuestions(response.data.questions);
      setRequestStatus('resolved');
    } catch (error: any) {
      setQuestions([]);
      setRequestStatus('rejected');
    }
  };

  const findAndUpdateQuestion = (id: string, status: boolean) => {
    const tempQuestions = [...questions];
    const index = tempQuestions.findIndex((question) => question.id === id);
    if (index !== -1) {
      tempQuestions.splice(index, 1, { ...tempQuestions[index], status });
      setQuestions(tempQuestions);
    }
  };

  const switchCallback = async (
    currentStatus: boolean,
    id: string,
    switchHelpers: SwitchHelpersType
  ) => {
    try {
      await axiosInstance.patch(`/survey/questions/update?id=${id}`, { status: currentStatus });
      findAndUpdateQuestion(id, currentStatus);
      switchHelpers.enableButton();
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
      switchHelpers.enableButton();
      switchHelpers.resetStatus(currentStatus);
    }
  };

  const showModal = (id: string) => {
    const deleteQuestion = async () => {
      try {
        await axiosInstance.delete(`/survey/questions/delete?id=${id}`);
        getQuestions();
        modalContextValue.hideModal();
      } catch (error: any) {
        const errorDetails = handleAxiosError(error);
        modalContextValue.setOutcomeModal('error', errorDetails.message);
      }
    };

    const getModalBody = () => {
      return (
        <div>
          <div>
            <div className="text-center font-bold">
              Are you sure you want to permanently delete this survey question? Once deleted, this
              action cannot be undone.
            </div>
          </div>
          <div className="text-center mt-5 mb-3">
            <Formik initialValues={{ delete: true }} onSubmit={deleteQuestion}>
              {({ isSubmitting }) => (
                <Form autoComplete="off" role="form">
                  <Button
                    type="submit"
                    label="Delete"
                    customClass="bg-red text-white mx-1 min-w-[120px]"
                    disabled={isSubmitting}
                  />
                  <Button
                    type="button"
                    label="Cancel"
                    handleClick={modalContextValue.hideModal}
                    customClass="bg-black-light text-white mx-1 min-w-[120px]"
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    };
    modalContextValue.setDataForModal({
      title: 'Delete Survey Question',
      headerClass: ' bg-red text-white',
      modalBody: getModalBody,
    });
  };

  const handleSortClick = () => {
    setFilterParams({ ...filterParams, sortOrder: 1 - filterParams.sortOrder });
  };

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  return (
    <div className="border border-gray-mid">
      <table className="w-full">
        <thead className="text-lg font-bold bg-brown-dark text-white text-left">
          <tr className="p-3">
            <th>
              <div className="flex items-center">
                <span className="mr-2">Question</span>
                <button type="button" onClick={handleSortClick}>
                  <ArrowUpDownIcon width={16} height={16} />
                </button>
              </div>
            </th>
            <th>Status</th>
            <th className="w-[60px]"></th>
          </tr>
        </thead>
        <tbody className="font-bold">
          {requestStatus === 'request' ? (
            <tr>
              <td colSpan={3} className="text-center">
                Loading . . .
              </td>
            </tr>
          ) : questions && questions.length > 0 ? (
            questions.map((item, index) => (
              <tr key={item.id} className={`${index % 2 === 0 ? ' bg-gray-light ' : 'bg-white'}`}>
                <td className="">
                  <div
                    className={`max-w-[900px] text-ellipsis overflow-hidden whitespace-nowrap text-blue underline ${index % 2 === 0 ? ' bg-gray-light ' : 'bg-white'} ${!item.status ? ' opacity-60 ' : 'opacity-1'}`}
                  >
                    <Link
                      to={`/survey-questions/${item.id}/edit`}
                      state={{ questionId: item.id, question: item.question }}
                    >
                      {item.question}
                    </Link>
                  </div>
                </td>
                <td className="capitalize">
                  <Switch id={item.id} initalState={item.status} callback={switchCallback} />
                </td>
                <td className="flex text-black">
                  <SurveyItemDeleteButton
                    id={item.id}
                    allowDelete={(!item.status && item.dormantDays > 180) || (!item.status && !item.hasSubmissions)}
                    callback={showModal}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} className="text-center">
                No questions available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default SurveyQuestionList;
