import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import Button from '../../../button';
import QuillEditorField from '../../../quill-editor/form-field';
import { activityTypes } from '../../../../common/data';
import ImageDropboxField from '../../../dropbox/image/form-field';
import axiosInstance from '../../../../common/interceptors';
import ActivityPreviewButton from '../button/preview';
import ActivityDeleteButton from '../button/delete';
import { BisonHideTemplateType, FormValuesType } from './types';
import { formSchema } from './schema';
import { instructionalImageDimensions } from './data';

function BisonHideTemplate({
  tourId,
  activityId,
  mode,
  initialValues = {
    name: '',
    description: '',
    instructions: '',
    instructionalImage: '',
  },
  actionHelpers,
}: BisonHideTemplateType) {
  const handleSubmit = async (
    values: FormValuesType,
    formikHelpers: FormikHelpers<FormValuesType>
  ) => {
    try {
      const { name, description, instructions, instructionalImage } = values;
      const formData = new FormData();
      formData.append('name', name);
      formData.append('description', description);
      formData.append('instructions', instructions);
      if (instructionalImage instanceof File) {
        formData.append('instructionImageFile', instructionalImage);
      }
      if (mode === 'add') {
        formData.append('tourId', tourId);
        formData.append('type', activityTypes.bisonHide);
        await axiosInstance.post('/activity', formData);
      } else {
        const response = await axiosInstance.patch(`/activity/update?id=${activityId}`, formData);
        const { activity } = response.data;
        formikHelpers.setFieldValue('instructionalImage', activity.instructionImageUrl);
      }
      actionHelpers.submitCallback();
    } catch (error) {
      actionHelpers.errorCallback(error);
    }
  };

  return (
    <div className="font-bold">
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, dirty, isValid }) => (
          <Form autoComplete="off">
            <div className="template-form-container">
              <div className="flex mb-7">
                <label
                  className="min-w-[175px] mr-4 h-12 flex items-center justify-end"
                  htmlFor="template-bh-name"
                >
                  Activity Name:
                </label>
                <div className="grow">
                  <Field type="text" name="name" id="template-bh-name" />
                  <ErrorMessage name="name" component="div" className="form-error" />
                </div>
              </div>
              <div className="flex mb-7">
                <label
                  className="min-w-[175px] mr-4 h-12 flex items-center justify-end"
                  htmlFor="template-bh-description"
                >
                  Activity Description:
                </label>
                <div className="grow">
                  <Field type="text" name="description" id="template-bh-description" />
                  <ErrorMessage name="description" component="div" className="form-error" />
                </div>
              </div>
              <div className="flex mb-7">
                <label
                  className="min-w-[175px] mr-4 h-12 flex items-center justify-end"
                  htmlFor="template-bh-instructions"
                >
                  Instructions:
                </label>
                <div className="grow font-normal">
                  <QuillEditorField name="instructions" editorId="template-bh-instructions" />
                </div>
              </div>
              <div className="flex mb-7">
                <label
                  className="max-w-[175px] mr-4 h-[175px] flex flex-col items-end justify-center"
                  htmlFor="template-bh-instructional-image"
                >
                  Instructional Image:
                  <span className="block text-right mt-2 text-sm">
                    Drag & Drop or <br /> Click to select image
                  </span>
                  <span className="block text-right mt-5 text-sm">
                    {`Image dimensions must be exactly ${instructionalImageDimensions.width} x ${instructionalImageDimensions.height} pixels, in JPG or PNG format only`}
                  </span>
                </label>
                <div className="grow">
                  <ImageDropboxField
                    dropboxId="template-bh-instructional-image"
                    name="instructionalImage"
                    width={instructionalImageDimensions.width}
                    height={instructionalImageDimensions.height}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
              <div className="flex pt-7">
                <label className="min-w-[175px] mr-4" />
                <div>
                  <Button
                    type="submit"
                    label={mode === 'edit' ? 'Update' : 'Add'}
                    disabled={isSubmitting}
                    customClass="bg-blue text-white min-w-[150px]"
                  />
                  {((mode === 'add' && dirty && isValid) || (mode === 'edit' && isValid)) && (
                    <ActivityPreviewButton activityType={activityTypes.bisonHide} data={values} />
                  )}
                  {mode === 'edit' && (
                    <ActivityDeleteButton
                      activityId={activityId}
                      deleteCallback={actionHelpers.deleteCallback}
                    />
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default BisonHideTemplate;
