import { Field } from 'formik';
import ImageDropbox from '..';
import { FieldRenderPropsType } from '../../../../common/types';
import { ImageDropboxFieldType } from './types';

const ImageDropboxField = ({
  dropboxId,
  name,
  width,
  height,
  disabled = false,
}: ImageDropboxFieldType) => {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldRenderPropsType) => (
        <>
          <ImageDropbox
            id={dropboxId}
            name={field.name}
            width={width}
            height={height}
            disabled={disabled}
            formHelpers={form}
            currentImage={field.value}
          />
          {meta.touched && meta.error && (
            <div className="error form-error font-bold" style={{ maxWidth: `${width}px` }}>
              {meta.error}
            </div>
          )}
        </>
      )}
    </Field>
  );
};

export default ImageDropboxField;
