import { useEffect, useState } from 'react';
import { RequestStatusType } from '../../../../common/types';
import axiosInstance from '../../../../common/interceptors';
import Pagination from '../../../pagination';
import { ArrowUpDownIcon } from '../../../svg';
import { paginationLimit, sortSequenceOrders, sortTimeOrders } from '../../../../common/data';
import { DailyResultsType } from './types';

function SurveyResultDailyList() {
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('request');
  const [results, setResults] = useState<DailyResultsType>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [filterParams, setFilterParams] = useState({
    currentPage: 1,
    sortBy: 'date',
    sortOrder: 0,
  });

  const getResults = async () => {
    try {
      setRequestStatus('request');
      const { currentPage, sortBy, sortOrder } = filterParams;
      const offset = (currentPage - 1) * paginationLimit;
      const querySortOrder =
        sortBy === 'date' ? sortTimeOrders[sortOrder] : sortSequenceOrders[sortOrder];
      const response = await axiosInstance.get(
        `/survey/submissions/daily?offset=${offset}&limit=${paginationLimit}&sortBy=${sortBy}&sortOrder=${querySortOrder}`
      );
      setTotalPages(Math.ceil(response.data.count / paginationLimit));
      setResults(response.data.submissions);
      setRequestStatus('resolved');
    } catch (error: any) {
      setTotalPages(0);
      setResults([]);
      setRequestStatus('rejected');
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setFilterParams({ ...filterParams, currentPage: pageNumber });
  };

  const handleSortClick = (event: any) => {
    const sortBy = event.currentTarget.name;
    const sortOrder = filterParams.sortBy === sortBy ? 1 - filterParams.sortOrder : 0;
    setFilterParams({ ...filterParams, sortBy, sortOrder, currentPage: 1 });
  };

  useEffect(() => {
    getResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  return (
    <div>
      <div className="border border-t-0 border-gray-mid mb-10">
        <table className="w-full">
          <thead className="text-lg font-bold bg-brown-dark text-white text-left">
            <tr className="p-3">
              <th>
                <div className="flex items-center">
                  <span className="mr-2">Date</span>
                  <button type="button" onClick={handleSortClick} name="date">
                    <ArrowUpDownIcon width={16} height={16} />
                  </button>
                </div>
              </th>
              <th>
                <div className="flex items-center">
                  <span className="mr-2">Tour Package Name</span>
                  <button type="button" onClick={handleSortClick} name="tourPackageName">
                    <ArrowUpDownIcon width={16} height={16} />
                  </button>
                </div>
              </th>
              <th className="text-right">1</th>
              <th className="text-right">2</th>
              <th className="text-right">3</th>
              <th className="text-right">4</th>
              <th className="text-right">5</th>
              <th className="text-right">Count</th>
            </tr>
          </thead>
          <tbody className="font-bold">
            {requestStatus === 'request' ? (
              <tr>
                <td colSpan={8} className="text-center">
                  Loading . . .
                </td>
              </tr>
            ) : results && results.length > 0 ? (
              results.map((item, index) => (
                <tr
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${item.submissionDate}-${index}`}
                  className={`${index % 2 === 0 ? ' bg-gray-light ' : 'bg-white'}`}
                >
                  <td className="whitespace-nowrap">{item.submissionDate}</td>
                  <td>{item.tourPackageName}</td>
                  <td className="text-right">{item['1']}%</td>
                  <td className="text-right">{item['2']}%</td>
                  <td className="text-right">{item['3']}%</td>
                  <td className="text-right">{item['4']}%</td>
                  <td className="text-right">{item['5']}%</td>
                  <td className="text-right">{item.count}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  No results available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalPages > 1 && (
        <Pagination
          currentPage={filterParams.currentPage}
          totalPages={totalPages}
          callback={handlePageChange}
        />
      )}
    </div>
  );
}

export default SurveyResultDailyList;
