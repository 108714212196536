import { useState } from 'react';
import { XCircleFillIcon } from '../svg';
import { SurveyItemDeleteButtonType } from './types';

function SurveyItemDeleteButton({ id, allowDelete, callback }: SurveyItemDeleteButtonType) {
  const [disabled, setDisabled] = useState(false);
  const handleClick = () => {
    setDisabled(false);
    callback(id);
  };
  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={!allowDelete || disabled}
      className={`${!allowDelete || disabled ? 'opacity-50 cursor-not-allowed' : 'not-disabled'}`}
    >
      <XCircleFillIcon width={26} height={26} customClass={`text-red`} />
    </button>
  );
}

export default SurveyItemDeleteButton;
