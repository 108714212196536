/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import axiosInstance from '../../../../../../common/interceptors';
import { handleAxiosError } from '../../../../../../common/helper';
import Button from '../../../../../button';
import Modal from '../../../../../modal';
import { RequestStatusType } from '../../../../../../common/types';
import { useModalContext } from '../../../../../../common/hooks';
import { TourActivityClearModalType } from './types';

function TourActivityClearModal({ tourId, toggleClearModal }: TourActivityClearModalType) {
  const modalContextValue = useModalContext();
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('request');
  const [clearInfo, setClearInfo] = useState({ isDataAvailable: '', timeElapsed: '', error: '' });

  const getTourClearInfo = async () => {
    try {
      setRequestStatus('request');
      const response = await axiosInstance.get(`/tour/clear/details?id=${tourId}`);
      setClearInfo({
        isDataAvailable: response.data.isDataAvailable,
        timeElapsed: response.data.timeElapsed,
        error: '',
      });
      setRequestStatus('resolved');
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      setClearInfo({ isDataAvailable: '', timeElapsed: '', error: errorDetails.message });
      setRequestStatus('rejected');
    }
  };

  const clearTour = async () => {
    try {
      await axiosInstance.post(`/tour/clear?id=${tourId}`);
      toggleClearModal();
      modalContextValue.setOutcomeModal('success', 'Activity cleared successfully!');
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      toggleClearModal();
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    }
  };

  useEffect(() => {
    getTourClearInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title="Clear Tour Package"
      headerClass="bg-orange-light text-brown-dark"
      toggleModal={toggleClearModal}
    >
      <div className="font-bold text-center">
        {requestStatus === 'request' ? (
          <div>Loading . . .</div>
        ) : clearInfo.error ? (
          <>
            <div>{clearInfo.error}</div>
            <div className="mt-5 mb-3">
              <Button
                type="button"
                label="Okay"
                handleClick={toggleClearModal}
                customClass="bg-black-light text-white mx-1 min-w-[120px]"
              />
            </div>
          </>
        ) : (
          <div>
            <div className="border-2 rounded-md p-3 mb-3 text-26">
              {clearInfo.isDataAvailable ? clearInfo.timeElapsed : 'No Data'}
            </div>
            {clearInfo.isDataAvailable ? (
              <>
                <div>
                  It has been {clearInfo.timeElapsed} since the last student of this tour completed
                  their survey. Do you still want to 'Clear' all the tablets of any data for the
                  most recent tour?
                </div>
                <div className="mt-5 mb-3">
                  <Formik initialValues={{ clear: true }} onSubmit={clearTour}>
                    {({ isSubmitting }) => (
                      <Form autoComplete="off" role="form">
                        <Button
                          type="submit"
                          label="Yes, Clear All Activity Data"
                          customClass="bg-red text-white mx-1 min-w-[220px]"
                          disabled={isSubmitting}
                        />
                        <Button
                          type="button"
                          label="Cancel"
                          handleClick={toggleClearModal}
                          customClass="bg-black-light text-white mx-1 min-w-[120px]"
                        />
                      </Form>
                    )}
                  </Formik>
                </div>
              </>
            ) : (
              <>
                <div>
                  There is currently no activity data available for this "Tour Package". This means
                  it has been more than (10) minutes since the last survey was completed for this
                  tour, and as a result, all the activity for this tour has already been purged.
                </div>
                <div className="mt-5 mb-3">
                  <Button
                    type="button"
                    label="Okay"
                    handleClick={toggleClearModal}
                    customClass="bg-black-light text-white mx-1 min-w-[120px]"
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default TourActivityClearModal;
