import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import HorizontalRule from '../../../horizontal-rule';
import CountHeader from '../count-header';
import Button from '../../../button';
import TourActivity from '../../activity';
import { handleAxiosError } from '../../../../common/helper';
import { useModalContext } from '../../../../common/hooks';

function TourAddActivity() {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const modalContextValue = useModalContext();
  const [activityAdded, setActivityAdded] = useState(false);

  const navigateToFinish = () => {
    navigate(`/tours/add/finish`, {
      replace: true,
      state: { tourId: state.tourId, from: 'create-activity' },
    });
  };

  const navigateToSelectTemplate = () => {
    navigate(`/tours/add/select-activity-type`, {
      replace: true,
      state: { tourId: state.tourId, from: 'create-activity' },
    });
  };

  const actionHelpers = {
    submitCallback: () => {
      setActivityAdded(true);
    },
    errorCallback: (error: any) => {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    },
  };

  return state && state.tourId && state.from === 'select-activity-type' ? (
    <div className="font-bold">
      <div className="my-5">
        <CountHeader count={3} title="Add Tour Activity" />
      </div>
      <HorizontalRule />
      <div className="my-10">
        {activityAdded ? (
          <div className="flex items-center justify-center min-h-[100px]">
            Activity added successfully!
          </div>
        ) : (
          <TourActivity
            tourId={state.tourId}
            activityId="create-activity"
            type={state.activityType}
            mode="add"
            actionHelpers={actionHelpers}
          />
        )}
      </div>
      <HorizontalRule />
      <div className="mt-4">
        <Button
          type="button"
          label="Back"
          handleClick={navigateToSelectTemplate}
          customClass="bg-black-light text-white"
        />
        <Button
          type="button"
          label="Add Another Activity"
          disabled={!activityAdded}
          handleClick={navigateToSelectTemplate}
          customClass="bg-orange-dark text-white mx-2 min-w-[200px]"
        />
        <Button
          type="button"
          label="Finish"
          disabled={!activityAdded}
          handleClick={navigateToFinish}
          customClass="bg-blue text-white"
        />
      </div>
    </div>
  ) : (
    <Navigate to="/tours/add" replace={true} />
  );
}

export default TourAddActivity;
