import { getImageUrlFromValue } from '../../../../../common/helper';
import { instructionalImageDimensions } from '../data';
import { RanchPricingSheetTemplatePreviewType } from './types';

function RanchPricingSheetTemplatePreview({ data }: RanchPricingSheetTemplatePreviewType) {
  const { name, description, instructions, instructionalImage, table } = data;
  const tableRows = Array.from(Array(Number(table.numberOfRows)).keys());
  const instructionalImageSrc = getImageUrlFromValue(instructionalImage);

  return (
    <div className="font-bold">
      <div className="text-26 mb-2 text-center">{name}</div>
      <div className="text-lg mb-7 text-center">{description}</div>
      <div
        className="font-normal text-base mb-7"
        dangerouslySetInnerHTML={{ __html: instructions }}
        data-testid="template-rps-preview-instructions"
      />
      <div className="mb-7">
        <div
          style={{
            width: `${instructionalImageDimensions.width}px`,
            height: `${instructionalImageDimensions.height}px`,
          }}
          className="overflow-hidden"
        >
          <img src={instructionalImageSrc} alt="Instructional" className="w-100 h-auto" />
        </div>
      </div>
      <div>
        <table className="w-full">
          <thead className="text-lg font-bold bg-gray-light text-center">
            <tr className="p-3">
              <th className="border w-[60px]">{table.rowHeaderIdentifiers[0]}</th>
              <th className="border">{table.rowHeaderIdentifiers[1]}</th>
              <th className="border">{table.rowHeaderIdentifiers[2]}</th>
            </tr>
          </thead>
          <tbody className="font-bold">
            {tableRows.map((row) => (
              <tr key={row + 1}>
                <td className="border">{row + 1}</td>
                <td className="border"></td>
                <td className="border"></td>
              </tr>
            ))}
            <tr>
              <td colSpan={2} className="text-right">
                {table.footerRowName}
              </td>
              <td className="border"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RanchPricingSheetTemplatePreview;
