import { Form, Formik } from 'formik';
import { useModalContext } from '../../../../../common/hooks';
import Button from '../../../../button';
import axiosInstance from '../../../../../common/interceptors';
import { handleAxiosError } from '../../../../../common/helper';
import { ExclamationTriangleFillIcon } from '../../../../svg';
import { TourDeleteButtonType } from './types';

function TourDeleteButton({ tourId, callback }: TourDeleteButtonType) {
  const modalContextValue = useModalContext();

  const deleteTour = async () => {
    try {
      await axiosInstance.delete(`/tour/delete?id=${tourId}`);
      callback();
      modalContextValue.hideModal();
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    }
  };

  const getDoubleConfirmModalBody = () => {
    return (
      <div>
        <div>
          <div className="text-center font-bold">
            <div className="mb-3">
              <ExclamationTriangleFillIcon width={60} height={60} customClass="mx-auto" />
            </div>
            <div>You cannot undo this action. Are you sure?</div>
          </div>
        </div>
        <div className="text-center mt-5 mb-3">
          <Formik initialValues={{ delete: true }} onSubmit={deleteTour}>
            {({ isSubmitting }) => (
              <Form autoComplete="off" role="form">
                <Button
                  type="submit"
                  label="Delete"
                  customClass="bg-red text-white mx-1 min-w-[120px]"
                  disabled={isSubmitting}
                />
                <Button
                  type="button"
                  label="Cancel"
                  handleClick={modalContextValue.hideModal}
                  customClass="bg-black-light text-white mx-1 min-w-[120px]"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  const showDoubleConfirmModal = () => {
    modalContextValue.setDataForModal({
      title: 'Delete Tour Package - Are you sure?',
      headerClass: ' bg-red text-white',
      modalBody: getDoubleConfirmModalBody,
    });
  };

  const getConfirmModalBody = () => {
    return (
      <div>
        <div>
          <div className="text-center font-bold">
            Are you sure you want to permanently delete this tour? Once deleted, this action cannot
            be undone ? Do you want to delete this tour?
          </div>
        </div>
        <div className="text-center mt-5 mb-3">
          <Button
            type="button"
            label="Delete"
            customClass="bg-red text-white mx-1 min-w-[120px]"
            handleClick={showDoubleConfirmModal}
          />
          <Button
            type="button"
            label="Cancel"
            handleClick={modalContextValue.hideModal}
            customClass="bg-black-light text-white mx-1 min-w-[120px]"
          />
        </div>
      </div>
    );
  };

  const showConfirmModal = () => {
    modalContextValue.setDataForModal({
      title: 'Delete Tour Package',
      headerClass: 'bg-red text-white',
      modalBody: getConfirmModalBody,
    });
  };

  return (
    <Button
      type="button"
      customClass="bg-red text-white ml-3 max-h-[36px]"
      handleClick={showConfirmModal}
      label="Delete"
    />
  );
}

export default TourDeleteButton;
