export const views = [
  {
    id: 1,
    name: 'Daily',
    key: 'daily',
  },
  {
    id: 2,
    name: 'Monthly',
    key: 'monthly',
  },
];
