import SectionHeader from '../../../components/section/header';
import ModalContextWrapper from '../../../components/wrapper/modal-context';
import SurveyQuestionsAddForm from '../../../components/survey-questions/form/add-question';

function SurveyQuestionsAddView() {
  return (
    <div>
      <SectionHeader title="Add Survey Question" />
      <div className="my-6">
        <ModalContextWrapper>
          <SurveyQuestionsAddForm />
        </ModalContextWrapper>
      </div>
    </div>
  );
}

export default SurveyQuestionsAddView;
