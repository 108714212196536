import { mixed, object, string } from 'yup';
import { validateImageDimensions } from '../../../../common/helper';
import { instructionalImageDimensions } from './data';

export const formSchema = object({
  name: string().required('Required'),
  description: string().required('Required'),
  instructionalImage: mixed<string | File>()
    .required('Required')
    .test(
      'valid-image-dimensions',
      `Image dimensions must be exactly ${instructionalImageDimensions.width}w x ${instructionalImageDimensions.height}h`,
      async (file) => {
        if (typeof file === 'string') return true;
        const isValid = await validateImageDimensions(
          file,
          instructionalImageDimensions.width,
          instructionalImageDimensions.height
        );
        return isValid;
      }
    ),
  // instructions: field-level validation
});
