import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from '../../button';
import wsmsmLogo from '../../../assets/images/wsm-sm-logo.png';
import { apiBaseUrl, appEnv, appEnvs } from '../../../common/data';
import { handleAxiosError } from '../../../common/helper';
import { loginSchema } from './schema';
import { LoginFormType } from './types';

function LoginForm({ showErrorModal }: LoginFormType) {
  const navigate = useNavigate();
  const handleSubmit = async (values: { email: string; password: string }) => {
    try {
      await axios.post(
        `${apiBaseUrl}/auth/login`,
        {
          email: values.email,
          password: values.password,
        },
        { withCredentials: appEnv !== appEnvs.test }
        /**
         * In case of same-origin, withCredentials is not required
         * { withCredentials: !!(appEnv !== appEnvs.prod && appEnv !== appEnvs.uat) }
         */
      );
      localStorage.setItem('rememberUser', 'yes');
      navigate('/dashboard');
    } catch (error: any) {
      const errorResponse = handleAxiosError(error);
      const errorMessage =
        errorResponse.status === 401
          ? 'The login credentials provided failed. Please contact your Systems Administrator for additional information.'
          : errorResponse.message;
      showErrorModal(errorMessage);
    }
  };
  return (
    <div className="w-full text-center font-bold max-w-[500px] rounded-b-2xl shadow-lg mx-4 sm:mx-6 my-6">
      <div className=" bg-brown-dark text-white text-26 p-4 rounded-t-2xl">Administrator Login</div>
      <div className="pt-4 pb-10 px-6">
        <div className="mb-3">
          <img className="w-[200px] h-auto m-auto" src={wsmsmLogo} alt="Western Spirit Logo" />
        </div>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={loginSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form autoComplete="off">
              <div className="max-w-[324px] w-full mx-auto mb-6">
                <Field type="email" name="email" placeholder="Username" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="form-error"
                  data-testid="usernameError"
                />
              </div>
              <div className="max-w-[324px] w-full mx-auto mb-6">
                <Field type="password" name="password" placeholder="Password" />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="form-error"
                  data-testid="passwordError"
                />
              </div>
              <div className="pt-4">
                <Button
                  type="submit"
                  label="Sign In"
                  disabled={isSubmitting}
                  customClass="bg-blue text-white"
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default LoginForm;
