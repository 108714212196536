import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../common/interceptors';
import { RequestStatusType } from '../../../common/types';
import TemplateHeader from '../template/header';
import TourActivity from '../activity';
import { handleAxiosError } from '../../../common/helper';
import { useModalContext } from '../../../common/hooks';
import { TourType } from './types';

function TourEdit() {
  const { id } = useParams();
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('request');
  const [tour, setTour] = useState<TourType | null>(null);
  const modalContextValue = useModalContext();

  const getTour = async () => {
    try {
      const response = await axiosInstance.get(`/tour?id=${id}`);
      setTour(response.data.tour);
      setRequestStatus('resolved');
    } catch (error: any) {
      setRequestStatus('rejected');
    }
  };

  const actionHelpers = {
    submitCallback: () => {
      modalContextValue.setOutcomeModal('success', 'Activity saved!');
    },
    deleteCallback: (activityId: string) => {
      if (tour && tour.activities) {
        const activities = tour.activities.filter((activity) => activity.id !== activityId);
        setTour({ ...tour, activities });
      }
    },
    errorCallback: (error: any) => {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    },
  };

  useEffect(() => {
    getTour();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="font-bold">
      {requestStatus === 'request' ? (
        <div className="text-center">Loading . . .</div>
      ) : tour && id ? (
        <div>
          {tour.activities.map((activity, index) => (
            <div key={activity.id} className="mt-10 mb-20">
              <TemplateHeader title={`Activity ${index + 1}`} customClass="mb-10" />
              <TourActivity
                tourId={id}
                activityId={activity.id}
                type={activity.type}
                mode="edit"
                details={activity}
                actionHelpers={actionHelpers}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center">No tour available</div>
      )}
    </div>
  );
}

export default TourEdit;
