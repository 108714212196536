import { useState } from 'react';
import { UserType } from '../../common/types';
import SectionHeader from '../../components/section/header';
import UserManagementAddForm from '../../components/user-management/form/add-user';
import UserManagementTable from '../../components/user-management/table';
import UserManagementEditForm from '../../components/user-management/form/edit-user';
import UserManagementUpdatePasswordForm from '../../components/user-management/form/update-password';
import ModalContextWrapper from '../../components/wrapper/modal-context';

function UserManagementView() {
  const [editUser, setEditUser] = useState<UserType | null>(null);
  const [usersApiCount, setUsersAPiCount] = useState<number>(1);
  const editCallback = (userDetails: UserType) => {
    setEditUser(userDetails);
  };

  const updateUsersAPiCount = () => {
    const newUsersAPiCount = usersApiCount + 1;
    setUsersAPiCount(newUsersAPiCount);
  };

  const resetEditUser = () => {
    setEditUser(null);
  };
  return (
    <div>
      <SectionHeader title="User Management" />
      <div className="font-bold my-6">
        <div className="text-lg inline-block rounded-t-md bg-brown-dark text-white text-center ml-8 py-1 px-4">
          User Management
        </div>
        <div className="bg-brown-dark text-white p-3">
          EXPLANATION: Use this component to create and manage Users within at the museum. There are
          two defined "roles" within the application. These include "Senior Administrator" and
          "Administrator". "Senior Administrators" have complete access to all components in the
          application, including but not limited to, User Management and Content controls.
          "Administrators" have access to all components except USER MANAGEMENT.
        </div>
      </div>
      <ModalContextWrapper>
        <div className="flex flex-wrap">
          <div className="max-w-[550px] mr-4 grow border border-gray-mid">
            <div className="text-lg font-bold bg-brown-dark text-white p-3">
              Add / Edit Users (All Fields Mandatory)
            </div>

            {editUser ? (
              <div>
                <UserManagementEditForm
                  user={editUser}
                  resetEditUser={resetEditUser}
                  updateUsersAPiCount={updateUsersAPiCount}
                />
                <UserManagementUpdatePasswordForm user={editUser} />
              </div>
            ) : (
              <UserManagementAddForm updateUsersAPiCount={updateUsersAPiCount} />
            )}
          </div>
          <div className="grow border border-gray-mid">
            <UserManagementTable usersApiCount={usersApiCount} editCallback={editCallback} />
          </div>
        </div>
      </ModalContextWrapper>
    </div>
  );
}

export default UserManagementView;
