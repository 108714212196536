import { useState } from 'react';
import Header from '../../components/header';
import Footer from '../../components/footer';
import LoginForm from '../../components/login/form';
import Modal from '../../components/modal';
import Button from '../../components/button';

function LoginView() {
  const [errorModal, setErrorModal] = useState('');
  const hideErrorModal = () => {
    setErrorModal('');
  };
  const showErrorModal = (message: string) => {
    setErrorModal(message);
  };

  return (
    <div className="min-h-screen flex flex-col justify-between ">
      <Header user={null} />
      <div className="flex justify-center">
        <LoginForm showErrorModal={showErrorModal} />
        {errorModal && (
          <Modal title="Failed Login" headerClass="bg-red text-white" toggleModal={hideErrorModal}>
            <div className="text-center">
              <div className="font-bold mb-5">{errorModal}</div>
              <Button
                type="button"
                label="Okay"
                customClass="bg-red text-white mb-3"
                handleClick={hideErrorModal}
              />
            </div>
          </Modal>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default LoginView;
