import HorizontalRule from '../../horizontal-rule';
import { SectionHeaderType } from './types';

function SectionHeader({
  title,
  textSize = 'text-xl',
  textTransform = 'uppercase',
}: SectionHeaderType) {
  return (
    <div className="flex items-center font-bold">
      <div className={`mr-6 ${textSize} ${textTransform}`}>{title}</div>
      <HorizontalRule />
    </div>
  );
}

export default SectionHeader;
