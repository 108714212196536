import { DrawBoxType } from './types';

function DrawBox({ title }: DrawBoxType) {
  return (
    <div className="w-full p-5 border border-gray-argent bg-gray-light rounded-xl">
      <div className="flex items-center justify-center p-2 h-[480px] text-26 font-normal bg-yellow-light">
        {title}
      </div>
    </div>
  );
}

export default DrawBox;
