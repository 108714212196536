import { formatTourActivityImages } from '../../../common/helper';

export const formatTemplateData = (data: any) => {
  return {
    ...data,
    instructionalImage: data.instructionImageUrl ? data.instructionImageUrl : '',
    images: formatTourActivityImages(data.image),
    table: data.tableDetails,
    footerTop: data.topFooter,
  };
};
