import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { RequestStatusType } from '../../../../common/types';
import TemplateHeader from '../../template/header';
import CountHeader from '../../add/count-header';
import HorizontalRule from '../../../horizontal-rule';
import Button from '../../../button';
import TourActivityPreview from '../../activity/preview';
import { handleAxiosError } from '../../../../common/helper';
import { useModalContext } from '../../../../common/hooks';
import { tourStatus } from '../../../../common/data';
import axiosInstance from '../../../../common/interceptors';
import { formatTemplateData } from '../../activity/helper';
import { TourType } from './types';
import TourStartOverButton from './button/start-over';

function TourAddFinish() {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const modalContextValue = useModalContext();

  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('request');
  const [tour, setTour] = useState<TourType | null>(null);

  const getTour = async () => {
    try {
      const response = await axiosInstance.get(`/tour?id=${state.tourId}`);
      setTour(response.data.tour);
      setRequestStatus('resolved');
    } catch (error: any) {
      setRequestStatus('rejected');
    }
  };

  const updateTourStatus = async () => {
    try {
      await axiosInstance.patch(`/tour/update?id=${state.tourId}`, { status: tourStatus.active });
      navigate(`/tours`, { replace: true });
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    }
  };

  useEffect(() => {
    if (state && state.tourId) {
      getTour();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return state && state.tourId && state.from === 'create-activity' ? (
    <div className="font-bold">
      <div className="my-5">
        <CountHeader count={4} title="Finish Tour Package" />
      </div>
      <HorizontalRule />
      <div className="my-10">
        {requestStatus === 'request' ? (
          <div className="text-center">Loading . . .</div>
        ) : tour ? (
          <div>
            {tour.activities.map((activity, index) => (
              <div key={activity.id} className="mt-10 mb-20">
                <TemplateHeader title={`Activity ${index + 1}`} customClass="mb-10" />
                <div className="max-w-[900px] m-auto">
                  <TourActivityPreview type={activity.type} values={formatTemplateData(activity)} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center">No tour available</div>
        )}
      </div>
      <HorizontalRule />
      <div className="mt-4 flex">
        <Formik initialValues={{ finish: true }} onSubmit={updateTourStatus}>
          {({ isSubmitting }) => (
            <Form autoComplete="off" role="form">
              <Button
                type="button"
                label="Finish Tour Package"
                disabled={isSubmitting}
                handleClick={updateTourStatus}
                customClass="bg-blue text-white min-w-[200px] mr-2"
              />
            </Form>
          )}
        </Formik>
        <TourStartOverButton tourId={state.tourId} />
      </div>
    </div>
  ) : (
    <Navigate to="/tours/add" replace={true} />
  );
}

export default TourAddFinish;
