import { PersonFillGearIcon } from '../../svg';
import { EditUserIconBoxType } from './types';

const EditUserIconBox = ({ userDetails, editCallback }: EditUserIconBoxType) => {
  const handleEditClick = () => {
    editCallback(userDetails);
  };

  return (
    <button type="button" className="p-1 inline-block border rounded" onClick={handleEditClick}>
      <PersonFillGearIcon />
    </button>
  );
};

export default EditUserIconBox;
