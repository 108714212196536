import { ErrorMessage, Field, Form, Formik } from 'formik';
import Button from '../../../button';
import { userRoleList, userStatusList } from '../../../../common/data';
import UserManagementFormLabel from '../label';
import { ExclamationTriangleFillIcon } from '../../../svg';
import { useModalContext } from '../../../../common/hooks';
import axiosInstance from '../../../../common/interceptors';
import { handleAxiosError } from '../../../../common/helper';
import { editUserSchema } from './schema';
import { UserManagementEditFormType } from './types';

function UserManagementEditForm({
  user,
  resetEditUser,
  updateUsersAPiCount,
}: UserManagementEditFormType) {
  const modalContextValue = useModalContext();

  const handleSubmit = async (values: any) => {
    try {
      await axiosInstance.patch(`/user/update?userId=${user.id}`, {
        firstName: values.firstName,
        lastName: values.lastName,
        role: values.role,
        status: values.status,
      });
      updateUsersAPiCount();
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    }
  };

  const handleDeleteUser = async (event: any) => {
    try {
      await axiosInstance.delete(`/user/delete?userId=${user.id}`);
      resetEditUser();
      updateUsersAPiCount();
      modalContextValue.hideModal();
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    }
  };

  const getModalBody = () => {
    return (
      <div>
        <div>
          <div className="text-center font-bold">
            <div className="mb-3">
              <ExclamationTriangleFillIcon width={60} height={60} customClass="mx-auto" />
            </div>
            <div className="mb-4 text-lg">YOU ARE ABOUT TO PERMANENTLY DELETE A USER</div>
            <div>
              Once delete an individual from the Western Spirit Museum of the West, they cannot be
              recovered. They will be unable to access the application, and their login credentials
              will no longer be available. If you are sure they should be permanently removed, click
              the "Delete User".
            </div>
          </div>
        </div>
        <div className="text-center mt-5 mb-3">
          <Formik initialValues={{ delete: true }} onSubmit={handleDeleteUser}>
            {({ isSubmitting }) => (
              <Form autoComplete="off" role="form">
                <Button
                  type="submit"
                  label="Delete User"
                  customClass="bg-red text-white mx-1 min-w-[120px]"
                  disabled={isSubmitting}
                />
                <Button
                  type="button"
                  label="Cancel"
                  handleClick={modalContextValue.hideModal}
                  customClass="bg-black-light text-white mx-1 min-w-[120px]"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  const showModal = () => {
    modalContextValue.setDataForModal({
      title: 'Delete User',
      modalClass: 'max-w-[740px]',
      headerClass: ' bg-red text-white',
      modalBody: getModalBody,
    });
  };

  return (
    <div className="font-bold p-4">
      <Formik
        initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          role: user.role,
          status: user.status,
        }}
        validationSchema={editUserSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off" role="form">
            <div className="flex mb-7">
              <UserManagementFormLabel label="First Name:" htmlFor="firstName" />
              <div className="grow">
                <Field type="text" name="firstName" id="firstName" />
                <ErrorMessage name="firstName" component="div" className="form-error" />
              </div>
            </div>
            <div className="flex mb-7">
              <UserManagementFormLabel label="Last Name:" htmlFor="lastName" />
              <div className="grow">
                <Field type="text" name="lastName" id="lastName" />
                <ErrorMessage name="lastName" component="div" className="form-error" />
              </div>
            </div>
            <div className="flex mb-7">
              <UserManagementFormLabel label="Email:" htmlFor="email" />
              <div className="grow">
                <Field type="email" name="email" id="email" disabled />
                <ErrorMessage name="email" component="div" className="form-error" />
              </div>
            </div>
            <div className="flex mb-7">
              <UserManagementFormLabel label="Role:" htmlFor="role" />
              <div className="grow">
                <Field as="select" name="role" id="role">
                  <option value="">Select Role</option>
                  {userRoleList.map((role) => (
                    <option key={role.key} value={role.key}>
                      {role.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="role" component="div" className="form-error" />
              </div>
            </div>
            <div className="flex mb-7">
              <UserManagementFormLabel label="Status:" htmlFor="status" />
              <div className="grow">
                <Field as="select" name="status" id="status">
                  <option value="">Select Status</option>
                  {userStatusList.map((status) => (
                    <option key={status.key} value={status.key}>
                      {status.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="status" component="div" className="form-error" />
              </div>
            </div>
            <div className="pt-4 text-right">
              <div>
                <Button
                  type="submit"
                  label="Update User"
                  disabled={isSubmitting}
                  customClass="bg-blue text-white max-w-[350px] w-full"
                />
              </div>
              <div className="mt-3">
                <Button
                  type="button"
                  label="Delete User"
                  disabled={isSubmitting}
                  handleClick={showModal}
                  customClass="bg-red text-white  max-w-[350px] w-full"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default UserManagementEditForm;
