import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../common/interceptors';
import { RequestStatusType, TourStatusKeyType } from '../../../common/types';
import { tourStatus } from '../../../common/data';
import { ToursType } from './types';
import TourDeleteButton from './button/delete';
import TourPauseButton from './button/pause';
import TourClearButton from './button/clear';

function TourList() {
  const [requestStatus, setRequestStatus] = useState<RequestStatusType>('request');
  const [tours, setTours] = useState<ToursType>([]);

  const getTours = async () => {
    try {
      setRequestStatus('request');
      const response = await axiosInstance.get(`/tour/all?offset=0&limit=100`);
      setTours(response.data.tours);
      setRequestStatus('resolved');
    } catch (error) {
      setRequestStatus('rejected');
    }
  };

  const updatePauseStatus = (tourId: string, status: TourStatusKeyType) => {
    const tempTours = [...tours];
    const index = tempTours.findIndex((tour) => tour.id === tourId);
    if (index !== -1) {
      tempTours.splice(index, 1, { ...tempTours[index], status });
      setTours(tempTours);
    }
  };

  useEffect(() => {
    getTours();
  }, []);

  return (
    <div className="max-w-[900px] m-auto font-bold">
      {requestStatus === 'request' ? (
        <div className="text-center">Loading . . .</div>
      ) : tours && tours.length > 0 ? (
        tours.map((tour) => (
          <div className="flex mb-5 items-center" key={tour.id} data-testid={tour.id}>
            <Link
              to={`/tours/${tour.id}/edit`}
              className="inline-block bg-brown-dark text-white grow rounded-md py-1.5 px-2"
            >
              {tour.name}
            </Link>
            <TourDeleteButton tourId={tour.id} callback={getTours} />
            <TourPauseButton
              tourId={tour.id}
              paused={tour.status === tourStatus.pause}
              updateTour={updatePauseStatus}
            />
            <TourClearButton tourId={tour.id} />
          </div>
        ))
      ) : (
        <div className="text-center">No tours available</div>
      )}
    </div>
  );
}

export default TourList;
