import { Outlet } from 'react-router-dom';
import SectionHeader from '../../../components/section/header';
import ModalContextWrapper from '../../../components/wrapper/modal-context';

function ToursAddView() {
  return (
    <div>
      <SectionHeader title="New Tour Package" />
      <div className="my-6">
        <div className="font-bold">
          Using the menus option below, click-through each of the steps associated with creating the
          "Tour Package", and the "Activities" within each Tour.
        </div>
        <ModalContextWrapper>
          <Outlet />
        </ModalContextWrapper>
      </div>
    </div>
  );
}

export default ToursAddView;
