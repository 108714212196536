import { instructionalImageDimensions } from '../data';
import cowBrandingDefaultImage from '../../../../../assets/images/cow-branding-default.png';
import { getImageUrlFromValue } from '../../../../../common/helper';
import { CattleBrandingTemplatePreviewType } from './types';

function CattleBrandingTemplatePreview({ data }: CattleBrandingTemplatePreviewType) {
  const { name, description, instructions, instructionalImage } = data;
  const instructionalImageSrc = getImageUrlFromValue(instructionalImage);
  return (
    <div className="font-bold">
      <div className="text-26 mb-2 text-center">{name}</div>
      <div className="text-lg mb-7 text-center">{description}</div>
      <div
        className="font-normal text-base mb-7"
        dangerouslySetInnerHTML={{ __html: instructions }}
        data-testid="template-cb-preview-instructions"
      />
      <div className="mb-7">
        <div
          style={{
            width: `${instructionalImageDimensions.width}px`,
            height: `${instructionalImageDimensions.height}px`,
          }}
          className="overflow-hidden"
        >
          <img src={instructionalImageSrc} alt="Instructional" className="w-100 h-auto" />
        </div>
      </div>
      <div className="border-2 bg-yellow-mid p-5">
        <img src={cowBrandingDefaultImage} alt="Branded Cow" className="w-100 h-auto" />
      </div>
    </div>
  );
}

export default CattleBrandingTemplatePreview;
