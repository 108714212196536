import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Button from '../../../button';
import axiosInstance from '../../../../common/interceptors';
import { handleAxiosError } from '../../../../common/helper';
import { useModalContext } from '../../../../common/hooks';
import { editQuestionSchema } from './schema';

function SurveyQuestionsEditForm() {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const modalContextValue = useModalContext();

  const navigateToSurveyQuestions = () => {
    navigate('/survey-questions');
  };
  const handleSubmit = async (values: any, formikHelpers: { resetForm: () => void }) => {
    try {
      await axiosInstance.patch(`/survey/questions/update?id=${state.questionId}`, {
        question: values.question,
      });
      navigateToSurveyQuestions();
    } catch (error: any) {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    }
  };

  useEffect(() => {
    if (!state) {
      navigateToSurveyQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    state && (
      <div className="font-bold">
        <Formik
          initialValues={{ question: state.question }}
          validationSchema={editQuestionSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form autoComplete="off">
              <div className="mb-3">
                <label htmlFor="question">
                  Edit Survey Question, then click "Save" button to save the changes
                </label>
                <div className="mt-3">
                  <Field type="text" name="question" id="question" as="textarea" maxLength={300} />
                  <ErrorMessage name="question" component="div" className="form-error" />
                </div>
              </div>
              <div className="flex">
                <div>
                  <Button
                    type="submit"
                    label="Save"
                    disabled={isSubmitting}
                    customClass="bg-blue text-white mr-2"
                  />
                  <Button
                    type="button"
                    label="Cancel"
                    customClass="bg-black-light text-white"
                    handleClick={navigateToSurveyQuestions}
                  />
                </div>
                <div className="grow text-right">
                  Remaining Characters:{' '}
                  <span className="text-blue">{300 - values.question.length}</span>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  );
}

export default SurveyQuestionsEditForm;
