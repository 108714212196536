import { Form, Formik } from 'formik';
import { useModalContext } from '../../../../../common/hooks';
import Button from '../../../../button';
import axiosInstance from '../../../../../common/interceptors';
import { handleAxiosError } from '../../../../../common/helper';
import { tourStatus } from '../../../../../common/data';
import { TourPauseButtonType } from './types';

function TourPauseButton({ tourId, paused, updateTour }: TourPauseButtonType) {
  const modalContextValue = useModalContext();

  const updateTourPauseStatus = async () => {
    const updateStatusTo = paused ? tourStatus.active : tourStatus.pause;
    try {
      await axiosInstance.patch(`/tour/update?id=${tourId}`, { status: updateStatusTo });
      updateTour(tourId, updateStatusTo);
      modalContextValue.hideModal();
    } catch (error) {
      const errorDetails = handleAxiosError(error);
      modalContextValue.setOutcomeModal('error', errorDetails.message);
    }
  };

  const getConfirmModalBody = () => {
    return (
      <div>
        <div>
          <div className="text-center font-bold">
            <div>
              {paused
                ? 'This tour is currently paused. To resume this tour, click the "Resume" button below.'
                : 'Pausing a tour does not delete it. It removes from view from the home screen tablet used by students.'}
            </div>
          </div>
        </div>
        <div className="text-center mt-5 mb-3">
          <Formik initialValues={{ pauseUpdate: true }} onSubmit={updateTourPauseStatus}>
            {({ isSubmitting }) => (
              <Form autoComplete="off" role="form">
                <Button
                  type="submit"
                  label={`${paused ? 'Resume' : 'Pause'}`}
                  customClass={`text-white mx-1 min-w-[120px] ${paused ? 'bg-blue' : 'bg-orange-dark'}`}
                  disabled={isSubmitting}
                />
                <Button
                  type="button"
                  label="Cancel"
                  handleClick={modalContextValue.hideModal}
                  customClass="bg-black-light text-white mx-1 min-w-[120px]"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  const showConfirmModal = () => {
    modalContextValue.setDataForModal({
      title: `${paused ? 'Resume' : 'Pause'} Tour Package`,
      headerClass: `text-white ${paused ? 'bg-orange-dark' : 'bg-brown-dark'}`,
      modalBody: getConfirmModalBody,
    });
  };

  return (
    <Button
      type="button"
      customClass={`ml-3 max-h-[36px] ${paused ? 'bg-orange-light text-red ' : 'bg-orange-dark text-white '}`}
      handleClick={showConfirmModal}
      label={`${paused ? 'Paused' : 'Pause'}`}
    />
  );
}

export default TourPauseButton;
