import { useState } from 'react';
import Button from '../../../../button';
import { TourClearButtonType } from './types';
import TourActivityClearModal from './modal';

function TourClearButton({ tourId }: TourClearButtonType) {
  const [showClearModal, setShowClearModal] = useState(false);

  const toggleClearModal = () => {
    setShowClearModal(!showClearModal);
  };

  return (
    <>
      <Button
        type="button"
        customClass="bg-orange-light text-brown-dark ml-3 max-h-[36px]"
        handleClick={toggleClearModal}
        label="Clear"
      />
      {showClearModal && (
        <TourActivityClearModal tourId={tourId} toggleClearModal={toggleClearModal} />
      )}
    </>
  );
}

export default TourClearButton;
