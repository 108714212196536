import SectionHeader from '../../../components/section/header';
import ModalContextWrapper from '../../../components/wrapper/modal-context';
import SurveyQuestionsEditForm from '../../../components/survey-questions/form/edit-question';

function SurveyQuestionsEditView() {
  return (
    <div>
      <SectionHeader title="Edit Survey Question" />
      <div className="my-6">
        <ModalContextWrapper>
          <SurveyQuestionsEditForm />
        </ModalContextWrapper>
      </div>
    </div>
  );
}

export default SurveyQuestionsEditView;
