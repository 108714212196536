import { instructionalImageDimensions } from '../data';
import wsmsmLogo from '../../../../../assets/images/wsm-sm-logo.png';
import footerDefaultImage from '../../../../../assets/images/footer-default.png';
import { getImageUrlFromValue } from '../../../../../common/helper';
import { FooterTemplatePreviewType } from './types';

function FooterTemplatePreview({ data }: FooterTemplatePreviewType) {
  const { footerTop, instructionalImage, footerContent } = data;
  const instructionalImageSrc = instructionalImage
    ? getImageUrlFromValue(instructionalImage)
    : footerDefaultImage;
  return (
    <div className="font-bold">
      <div className="w-[350px] h-auto m-auto mb-7">
        <img src={wsmsmLogo} alt="Logo" className="w-100 h-auto" />
      </div>
      <div
        className="font-normal text-base mb-7"
        dangerouslySetInnerHTML={{ __html: footerTop }}
        data-testid="template-footer-preview-top"
      />
      <div className="mb-7">
        <div
          style={{
            width: `${instructionalImageDimensions.width}px`,
            height: `${instructionalImageDimensions.height}px`,
          }}
          className="m-auto overflow-hidden"
        >
          <img src={instructionalImageSrc} alt="Instructional" className="w-100 h-auto" />
        </div>
      </div>
      <div
        className="font-normal text-base"
        dangerouslySetInnerHTML={{ __html: footerContent }}
        data-testid="template-footer-preview-content"
      />
    </div>
  );
}

export default FooterTemplatePreview;
