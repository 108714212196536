import React from 'react';
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import DashboardView from './views/dashboard';
import LoginView from './views/login';
import MainLayout from './components/layout/main';
import UserManagementView from './views/user-management';
import SurveyQuestionsView from './views/survey-questions';
import SurveyQuestionsAddView from './views/survey-questions/add';
import SurveyQuestionsEditView from './views/survey-questions/edit';
import NoMatchView from './views/no-match';
import SurveyResultsView from './views/survey-results';
import AuthorizationCheckWrapper from './components/wrapper/authorization-check';
import { userRoles } from './common/data';
import ToursView from './views/tours';
import ToursAddView from './views/tours/add';
import TourAddName from './components/tours/add/name';
import TourSelectTemplate from './components/tours/add/select-template';
import TourAddActivity from './components/tours/add/activity';
import TourAddFinish from './components/tours/add/finish';
import ToursEditView from './views/tours/edit';

function App() {
  const loginLoader = () => {
    const rememberUser = localStorage.getItem('rememberUser');
    if (rememberUser === 'yes') {
      throw redirect('/dashboard');
    }
    return null;
  };

  const mainLayoutLoader = () => {
    const rememberUser = localStorage.getItem('rememberUser');
    if (rememberUser !== 'yes') {
      throw redirect('/');
    }
    return null;
  };

  const router = createBrowserRouter([
    {
      path: '/',
      loader: loginLoader,
      element: <LoginView />,
    },
    {
      path: '/login',
      loader: loginLoader,
      element: <LoginView />,
    },
    {
      element: <MainLayout />,
      loader: mainLayoutLoader,
      children: [
        {
          path: '/dashboard',
          element: <DashboardView />,
        },
        {
          path: '/user-management',
          element: (
            <AuthorizationCheckWrapper allowedRoles={[userRoles.superAdmin.key]}>
              <UserManagementView />
            </AuthorizationCheckWrapper>
          ),
        },
        {
          path: '/survey-questions',
          element: <SurveyQuestionsView />,
        },
        {
          path: '/survey-questions/add',
          element: <SurveyQuestionsAddView />,
        },
        {
          path: '/survey-questions/:id/edit',
          element: <SurveyQuestionsEditView />,
        },
        {
          path: '/survey-results',
          element: <SurveyResultsView />,
        },
        {
          path: '/tours',
          element: <ToursView />,
        },
        {
          element: <ToursAddView />,
          children: [
            {
              path: '/tours/add',
              element: <TourAddName />,
            },
            {
              path: '/tours/add/select-activity-type',
              element: <TourSelectTemplate />,
            },
            {
              path: '/tours/add/create-activity',
              element: <TourAddActivity />,
            },
            {
              path: '/tours/add/finish',
              element: <TourAddFinish />,
            },
          ],
        },
        {
          path: '/tours/:id/edit',
          element: <ToursEditView />,
        },
      ],
    },
    {
      path: '*',
      element: <NoMatchView />,
    },
  ]);
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
