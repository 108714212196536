import SectionHeader from '../../../components/section/header';
import TourEdit from '../../../components/tours/edit';
import ModalContextWrapper from '../../../components/wrapper/modal-context';

function ToursEditView() {
  return (
    <div>
      <SectionHeader title="Tour Package Editor" />
      <div className="my-6">
        <ModalContextWrapper>
          <TourEdit />
        </ModalContextWrapper>
      </div>
    </div>
  );
}

export default ToursEditView;
