import { ErrorMessage, Field, Form, Formik } from 'formik';
import Button from '../../../button';
import QuillEditorField from '../../../quill-editor/form-field';
import DrawBox from '../draw-box';
import { activityTypes } from '../../../../common/data';
import axiosInstance from '../../../../common/interceptors';
import ActivityPreviewButton from '../button/preview';
import ActivityDeleteButton from '../button/delete';
import { FormValuesType, GeneralActivityTemplateType } from './types';
import { formSchema } from './schema';

function GeneralActivityTemplate({
  tourId,
  activityId,
  mode,
  initialValues = {
    name: '',
    description: '',
    instructions: '',
  },
  actionHelpers,
}: GeneralActivityTemplateType) {
  const handleSubmit = async (values: FormValuesType) => {
    try {
      const { name, description, instructions } = values;
      const formData = new FormData();
      formData.append('name', name);
      formData.append('description', description);
      formData.append('instructions', instructions);
      if (mode === 'add') {
        formData.append('tourId', tourId);
        formData.append('type', activityTypes.generalActivity);
        await axiosInstance.post('/activity', formData);
      } else {
        await axiosInstance.patch(`/activity/update?id=${activityId}`, formData);
      }
      actionHelpers.submitCallback();
    } catch (error) {
      actionHelpers.errorCallback(error);
    }
  };

  return (
    <div className="font-bold">
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, dirty, isValid }) => (
          <Form autoComplete="off">
            <div className="template-form-container">
              <div className="flex mb-7">
                <label
                  className="min-w-[175px] mr-4 h-12 flex items-center justify-end"
                  htmlFor="template-ga-name"
                >
                  Activity Name:
                </label>
                <div className="grow">
                  <Field type="text" name="name" id="template-ga-name" />
                  <ErrorMessage name="name" component="div" className="form-error" />
                </div>
              </div>
              <div className="flex mb-7">
                <label
                  className="min-w-[175px] mr-4 h-12 flex items-center justify-end"
                  htmlFor="template-ga-description"
                >
                  Activity Description:
                </label>
                <div className="grow">
                  <Field type="text" name="description" id="template-ga-description" />
                  <ErrorMessage name="description" component="div" className="form-error" />
                </div>
              </div>
              <div className="flex mb-7">
                <label
                  className="min-w-[175px] mr-4 h-12 flex items-center justify-end"
                  htmlFor="template-ga-instructions"
                >
                  Instructions:
                </label>
                <div className="grow font-normal">
                  <QuillEditorField name="instructions" editorId="template-ga-instructions" />
                </div>
              </div>
              <div className="flex mb-7">
                <label className="min-w-[175px] mr-4" />
                <DrawBox title="Student Draw Space" />
              </div>
              <div className="flex pt-7">
                <label className="min-w-[175px] mr-4" />
                <div>
                  <Button
                    type="submit"
                    label={mode === 'edit' ? 'Update' : 'Add'}
                    disabled={isSubmitting}
                    customClass="bg-blue text-white min-w-[150px]"
                  />
                  {((mode === 'add' && dirty && isValid) || (mode === 'edit' && isValid)) && (
                    <ActivityPreviewButton
                      activityType={activityTypes.generalActivity}
                      data={values}
                    />
                  )}
                  {mode === 'edit' && (
                    <ActivityDeleteButton
                      activityId={activityId}
                      deleteCallback={actionHelpers.deleteCallback}
                    />
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default GeneralActivityTemplate;
