import { Link } from 'react-router-dom';
import Header from '../../components/header';
import Footer from '../../components/footer';

function NoMatchView() {
  return (
    <div className="min-h-screen flex flex-col justify-between ">
      <Header user={null} />
      <div className="text-center">
        <p className="font-bold text-2xl mb-4">No Page Found</p>
        <Link
          to="/dashboard"
          className="inline-block font-bold bg-blue text-white rounded-md py-1.5 px-2 shadow-md min-w-[100px]"
        >
          Home
        </Link>
      </div>

      <Footer />
    </div>
  );
}

export default NoMatchView;
