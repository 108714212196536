import { useMemo, useState } from 'react';
import { ModalContext } from '../../../common/context';
import Modal from '../../modal';
import { DataForModalType } from '../../../common/types';
import { ModalContextWrapperType } from './types';

function ModalContextWrapper({ children }: ModalContextWrapperType) {
  const [dataForModal, setDataForModal] = useState<DataForModalType>(null);
  const hideModal = () => {
    setDataForModal(null);
  };
  const setOutcomeModal = (type: 'error' | 'success', message: string) => {
    setDataForModal({
      title: type === 'error' ? 'Error' : 'Success',
      headerClass: type === 'error' ? 'bg-red text-white' : 'bg-black-light text-white',
      modalBody: () => <div className="text-center font-bold">{message}</div>,
    });
  };

  const contextValue = useMemo(() => {
    return { dataForModal, setDataForModal, hideModal, setOutcomeModal };
  }, [dataForModal]);

  return (
    <div>
      <ModalContext.Provider value={contextValue}>
        {children}
        {dataForModal && (
          <Modal
            title={dataForModal.title}
            toggleModal={hideModal}
            {...(dataForModal.modalClass && { modalClass: dataForModal.modalClass })}
            {...(dataForModal.headerClass && { headerClass: dataForModal.headerClass })}
          >
            {dataForModal.modalBody()}
          </Modal>
        )}
      </ModalContext.Provider>
    </div>
  );
}

export default ModalContextWrapper;
